import Search from "./Search";
import "../../scrollerStyle.css";
import { db } from "../../firebase";
import ActiveChatList from "./ActiveChatList";
import ArchiveChatList from "./ArchiveChatList";
import { useChat } from "../../context/ChatContext";
import { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { collection, query, where, or, onSnapshot } from "firebase/firestore";

function Sidebar({ setChatDocId, resetChatDocId }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { usernames } = useChat();
  const [chats, setChats] = useState([]);
  const { currentUser } = useContext(AuthContext);
  const [pinnedChats, setPinnedChats] = useState([]);
  const [archivedChats, setArchivedChats] = useState([]);

  // Fetch active chats based on P1Status or P2Status in real-time, excluding chats with status "read-only-archive"
  useEffect(() => {
    if (currentUser) {
      let q;
      if (currentUser) {
        // Check if currentUser is participant1 or participant2
        q = query(
          collection(db, "chats"),
          or(
            where("participant1", "==", currentUser.email),
            where("participant2", "==", currentUser.email)
          )
        );
      }

      // Set up a real-time listener for chat data
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const activeChats = snapshot.docs
          .map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }))
          .filter((chat) => {
            // Exclude chats with 'status' set to 'read-only-archive'
            if (chat.status === "read-only-archive") {
              return false;
            }

            // Retrieve if status is 'active' or if the status field doesn't exist
            if (
              chat.participant1 === currentUser.email &&
              (chat.P1Status === "active" || chat.P1Status === undefined)
            ) {
              return true;
            }
            if (
              chat.participant2 === currentUser.email &&
              (chat.P2Status === "active" || chat.P2Status === undefined)
            ) {
              return true;
            }
            return false;
          });
        setChats(activeChats);
      });

      // Clean up the listener on unmount
      return () => unsubscribe();
    }
  }, [currentUser]);

  // Fetch archived chats
  useEffect(() => {
    if (currentUser) {
      let q;
      q = query(
        collection(db, "chats"),
        or(
          where("participant1", "==", currentUser.email),
          where("participant2", "==", currentUser.email)
        )
      );

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const fetchedChats = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        // Separate archived and read-only-archive chats
        const archiveChats = fetchedChats.filter(
          (chat) =>
            (chat.participant1 === currentUser.email &&
              chat.P1Status === "archived") ||
            (chat.participant2 === currentUser.email &&
              chat.P2Status === "archived")
        );

        const readOnlyArchiveChats = fetchedChats.filter(
          (chat) => chat.status === "read-only-archive"
        );

        // Combine both lists for rendering in ArchiveChatList
        setArchivedChats([...archiveChats, ...readOnlyArchiveChats]);
      });

      return () => unsubscribe();
    }
  }, [currentUser]);

  // Fetch pinned status for the current user
  useEffect(() => {
    if (currentUser) {
      const q1 = query(
        collection(db, "chats"),
        where("pinnedByP1", "==", true),
        where("participant1", "==", currentUser.email)
      );
      const q2 = query(
        collection(db, "chats"),
        where("pinnedByP2", "==", true),
        where("participant2", "==", currentUser.email)
      );

      // Listen to real-time updates for pinned chats where the user is participant1
      const unsubscribe1 = onSnapshot(q1, (snapshot) => {
        const pinnedChatsP1 = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        // Merge with current pinned chats
        setPinnedChats((prevPinnedChats) => {
          const filteredPrevChats = prevPinnedChats.filter(
            (chat) => chat.participant1 !== currentUser.email
          );
          return [...filteredPrevChats, ...pinnedChatsP1];
        });
      });

      // Listen to real-time updates for pinned chats where the user is participant2
      const unsubscribe2 = onSnapshot(q2, (snapshot) => {
        const pinnedChatsP2 = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        // Merge with current pinned chats
        setPinnedChats((prevPinnedChats) => {
          const filteredPrevChats = prevPinnedChats.filter(
            (chat) => chat.participant2 !== currentUser.email
          );
          return [...filteredPrevChats, ...pinnedChatsP2];
        });
      });

      // Clean up the listeners on unmount
      return () => {
        unsubscribe1();
        unsubscribe2();
      };
    }
  }, [currentUser]);

  // Function to handle chat selection and navigate to that chat
  const handleChatSelect = (otherParticipantName, chatId) => {
    if (typeof otherParticipantName === "string") {
      const formattedName = otherParticipantName
        .toLowerCase()
        .replace(" ", "-");
      setChatDocId(chatId);
      navigate(`/starchat/chat/${formattedName}`);
    } else {
      alert("There was an error");
    }
  };

  // Function to handle archived chats selection and navigate to that chat
  const handleArchivedChatSelect = (otherParticipantName, chatId) => {
    if (typeof otherParticipantName === "string") {
      const formattedName = otherParticipantName
        .toLowerCase()
        .replace(" ", "-");
      setChatDocId(chatId);
      navigate(`/starchat/archive/${formattedName}`);
      console.log(chatId);
    } else {
      alert("There was an error");
    }
  };

  return (
    <div className="w-72 bg-gray-100 rounded-l-lg overflow-y-auto custom-scrollbar border border-gray-200 border-r border-r-gray-300">
      <div className="sticky top-0 bg-gray-100 z-20 ">
        <Link to="/starchat" onClick={() => resetChatDocId()}>
          <span className="text-gray-900 font-bold text-xl pl-4 pt-4 mb-4 block">
            Star Chat
          </span>
        </Link>
        <div className="mx-4">
          <Search />
        </div>
        {location.pathname.includes("/starchat/archive") ? (
          <div className="flex justify-center">
            <span className="w-full text-gray-700 font-semibold text-lg text-center py-1 border-t border-b border-gray-300 bg-gray-100">
              Archived Chats
            </span>
          </div>
        ) : (
          <div className="flex justify-center">
            <span className="w-[22rem] text-gray-700 font-semibold text-lg text-center py-1 border-t border-b border-gray-300 bg-gray-100">
              Chats
            </span>
          </div>
        )}
      </div>

      <div className="flex flex-col items-center">
        {location.pathname.includes("/starchat/archive") ? (
          <>
            <ArchiveChatList
              chats={[...archivedChats]}
              usernames={usernames}
              pinnedChats={pinnedChats}
              currentUser={currentUser}
              handleArchivedChatSelect={handleArchivedChatSelect}
            />
          </>
        ) : (
          <>
            <ActiveChatList
              chats={chats}
              usernames={usernames}
              pinnedChats={pinnedChats}
              currentUser={currentUser}
              handleChatSelect={handleChatSelect}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default Sidebar;
