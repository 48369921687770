import React from "react";
import { Link } from "react-router-dom";
import { GoLinkExternal } from "react-icons/go";

function StarConnectSettings({ isNavOpen }) {
  return (
    <div
      className={`pl-2 pr-5 pb-4 -mr-6 -mt-5 space-y-6 overflow-y-auto custom-scrollbar ${
        isNavOpen ? "w-[calc(100vw-35rem)]" : "w-[calc(100vw-22rem)]"
      } h-[calc(100vh-2.5rem)]`}
    >
      <h2 className="mb-4 text-xl font-medium">Star Connect</h2>
      {/* Contact Actions */}
      <div className="p-4 border border-gray-200 rounded-lg shadow-md">
        <h3 className="text-lg font-semibold text-gray-700 mb-2">
          Contact Avatar Preferences
        </h3>
        <p className="text-md text-gray-600 mb-4">
          Choose whether to see contact avatars with colored backgrounds or
          white backgrounds with a gradient ring for encrypted contacts.
        </p>
        ILLUSTRATION HERE
      </div>

      {/* Contact display */}
      <div className="p-4 border border-gray-200 rounded-lg shadow-md">
        <h3 className="text-lg font-semibold text-gray-700 mb-2">
          Contact Display
        </h3>
        <p className="text-md text-gray-600 mb-4">
          Choose how your contacts are displayed in Star Connect. You can switch
          between a compact list view or a visually appealing card view.
        </p>
        ILLUSTRATION HERE
        <div className="flex items-center justify-between mb-3">
          <div className="flex items-center ml-auto">
            <span className="text-sm text-gray-600">List</span>
            <label className="relative mx-2 inline-flex items-center cursor-pointer">
              <input type="checkbox" className="sr-only peer" />
              <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-checked:bg-indigo-500 peer-focus:ring-2 transition-all" />
              <div className="absolute top-0.5 left-0.5 w-5 h-5 bg-white rounded-full shadow peer-checked:translate-x-5 transition-transform" />
            </label>
            <span className="text-sm text-gray-600">Card</span>
          </div>
        </div>
      </div>

      {/* Labels */}
      <div className="flex items-center p-4 border border-gray-200 rounded-lg shadow-md">
        <h3 className="text-lg font-semibold text-gray-700">Manage Labels</h3>
        <Link
          to="/settings"
          className="flex text-sm ml-auto hover:underline hover:text-blue-500"
        >
          Manage labels
          <GoLinkExternal className="my-auto ml-1" />
        </Link>
      </div>

      {/* Call/Message icons */}
      <div className="p-4 border border-gray-200 rounded-lg shadow-md">
        <h3 className="text-lg font-semibold text-gray-700 mb-2">
          Call/Mesage shortcuts <i>(list-view)</i>
        </h3>
        <p className="text-md text-gray-600 mb-4">
          Choose whether you want to see the call/message shortcuts next to the
          contact name in the sidebar. This setting is only applied if you're
          using <i>(list-view)</i>
        </p>
        ILLUSTRATION HERE
        {/* Current Font */}
        <div className="flex justify-between items-center">
          <p className="text-md mt-8 font-medium underline underline-offset-2 text-gray-600">
            Current setting:
            <span className="font-semibold inline-block ml-1">ON</span>
          </p>
          <button className="px-3 py-2 mt-3 font-medium rounded duration-150 ease-in-out cursor-pointer shadow hover:shadow-indigo-500 bg-gray-200 text-gray-700">
            Save Shortcut Setting
          </button>
        </div>
      </div>

      {/* Birthday Notifications */}
      <div className="flex p-4 border border-gray-200 rounded-lg shadow-md">
        <div className="block">
          <h3 className="text-lg font-semibold text-gray-700">
            Birthday Notifications
          </h3>
          <p className="text-md text-gray-600">
            When one of your contacts has their birthday, do you want to receive
            a notification/reminder?
          </p>
        </div>
        <div className="flex items-center ml-auto space-x-4">
          <span className="text-sm text-gray-600">Off</span>
          <label className="relative inline-flex items-center cursor-pointer">
            <input type="checkbox" className="sr-only peer" />
            <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-checked:bg-indigo-500 peer-focus:ring-2 transition-all" />
            <div className="absolute top-0.5 left-0.5 w-5 h-5 bg-white rounded-full shadow peer-checked:translate-x-5 transition-transform" />
          </label>
          <span className="text-sm text-gray-600">On</span>
        </div>
      </div>

      {/* Birthday Notifications */}
      <div className="flex p-4 border border-gray-200 rounded-lg shadow-md">
        <div className="block">
          <h3 className="text-lg font-semibold text-gray-700">
            Archive Button Visibility
          </h3>
          <p className="text-md text-gray-600">
            Choose whether you want to see the archive button in the sidebar.
          </p>
        </div>
        <div className="flex items-center ml-auto space-x-4">
          <span className="text-sm text-gray-600">Off</span>
          <label className="relative inline-flex items-center cursor-pointer">
            <input type="checkbox" className="sr-only peer" />
            <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-checked:bg-indigo-500 peer-focus:ring-2 transition-all" />
            <div className="absolute top-0.5 left-0.5 w-5 h-5 bg-white rounded-full shadow peer-checked:translate-x-5 transition-transform" />
          </label>
          <span className="text-sm text-gray-600">On</span>
        </div>
      </div>

      {/* Account Actions Card */}
      <div className="p-4 border border-gray-200 rounded-lg shadow-md">
        <h3 className="text-lg text-left font-semibold text-gray-700 mb-2">
          Account Actions
        </h3>
        <div className="flex mt-3 mb-1 mx-10 justify-between space-x-10">
          {/* Set to Default Section */}
          <div className="my-auto items-center p-3 rounded-lg border border-gray-200 max-w-[30rem]">
            <span>Set All Contacts to Default:</span>
            <button className="text-blue-500 font-medium text-sm px-2 py-1 ml-2 rounded hover:text-white hover:bg-blue-500 duration-200 ease-in-out">
              Set to Default
            </button>
            <p className="mt-1 text-sm text-gray-500 leading-relaxed">
              Clicking <span className="font-semibold">Set to default</span>{" "}
              sends a mail to your registered email address, containing a link
              through which you can change/reset your password.
            </p>
          </div>
          {/* Archive/Unarchive Section */}
          <div className="my-auto items-center p-3 rounded-lg border border-gray-200 max-w-[30rem]">
            <span>Archive/Unarchive:</span>
            <button className="text-indigo-500 text-sm font-medium px-2 py-1 ml-2 rounded hover:text-white hover:bg-indigo-500 duration-200 ease-in-out">
              Archive
            </button>
            <button className="text-indigo-500 text-sm font-medium px-2 py-1 ml-2 rounded hover:text-white hover:bg-indigo-500 duration-200 ease-in-out">
              Unarchive
            </button>
            <p className="mt-1 text-sm text-gray-500 leading-relaxed">
              Clicking <span className="font-semibold">Archive/Unarchive</span>{" "}
              will archive or unarchive all your contacts. This action may cause
              organization errors. Be cautious.
            </p>
          </div>
          {/* Delete Account Section */}
          <div className="my-auto items-center p-3 rounded-lg border border-gray-200 max-w-[30rem]">
            <span>Delete All Contacts:</span>
            <button className="text-red-500 text-sm font-medium px-2 py-1 ml-2 rounded hover:text-white hover:bg-red-500 duration-200 ease-in-out">
              Delete
            </button>
            <p className="mt-1 text-sm text-gray-500 leading-relaxed">
              Clicking <span className="font-semibold">Delete</span> will delete
              all your contacts. Keep in mind that this action is an
              irreversible action.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StarConnectSettings;
