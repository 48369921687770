import {
  AiFillStar,
  AiOutlineEdit,
  AiOutlineCopy,
  AiOutlineStar,
  AiOutlineUndo,
  AiOutlineDelete,
  AiOutlineArrowRight,
  AiOutlineDelete as AiOutlineDeletePermanently,
} from "react-icons/ai";
import {
  where,
  query,
  collection,
  onSnapshot,
  serverTimestamp,
} from "firebase/firestore";
import { format } from "date-fns";
import { db } from "../../firebase";
import { FiX } from "react-icons/fi";
import { Link } from "react-router-dom";
import { RiExchangeLine } from "react-icons/ri";
import { IoWarningOutline } from "react-icons/io5";
import { AuthContext } from "../../context/AuthContext";
import { Base64Util } from "../../utils/B64EncodeAndDecode";
import { MdArchive, MdLabelOutline, MdUnarchive } from "react-icons/md";
import { useState, useEffect, useCallback, useRef, useContext } from "react";

function Note({
  labels,
  userNotes,
  onNoteCopy,
  onNoteDelete,
  onNoteRestore,
  onNoteStarring,
  onNoteSaveEdit,
  onNoteArchiving,
  showStar = true,
  showCopy = true,
  showEdit = true,
  onLabelAssigning,
  showLabels = true,
  showDelete = true,
  showArchive = true,
  onNotePermanentDelete,
  showUnarchive = false,
  showPDAndRestore = false,
}) {
  const EBModalRef = useRef(null);
  const EEModalRef = useRef(null);
  const noteModalRef = useRef(null);
  const { currentUser } = useContext(AuthContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);
  const [selectedLabels, setSelectedLabels] = useState([]);
  const [isEditingMode, setIsEditingMode] = useState(false);
  const [isLabelModalOpen, setIsLabelModalOpen] = useState(false);
  const [encryptionErrorModal, setEncryptionErrorModal] = useState(false);
  const [encryptionBannerModal, setEncryptionBannerModal] = useState(false);
  const [currentSystemEncryption, setCurrentSystemEncryption] = useState("");

  // Truncate text beyond a specified word limit
  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };

  useEffect(() => {
    // Retrieve user document in real-time
    const getUserDoc = () => {
      const docIdQuery = query(
        collection(db, "users"),
        where("email", "==", currentUser.email)
      );

      // Set up the Firestore listener
      const unsubscribeUserDoc = onSnapshot(docIdQuery, (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          setCurrentSystemEncryption(doc.data().systemEncryption);
        });
      });

      return unsubscribeUserDoc;
    };

    const unsubscribe = getUserDoc();

    // Cleanup function to unsubscribe from Firestore
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [currentUser.email]);

  // Function to OPEN a modal for selected note
  const handleNoteClick = (note) => {
    setSelectedNote(note);
    setIsModalOpen(true);
  };

  // Function to handle modal CLOSE
  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
    setSelectedNote(null);
    setIsLabelModalOpen(false);
    setIsEditingMode(false);
    setEncryptionBannerModal(false);
    setEncryptionErrorModal(false);
  }, []);

  // Handle keydown event ESCAPE
  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "Escape") {
        handleCloseModal();
      }
    },
    [handleCloseModal]
  );

  // Handle clicks outside the modal
  const handleClickOutside = useCallback(
    (event) => {
      if (event.target.classList.contains("modal-overlay")) {
        handleCloseModal();
      }
    },
    [handleCloseModal]
  );

  // useEffect to add/remove event listeners based on modal state
  useEffect(() => {
    if (isModalOpen) {
      document.addEventListener("keydown", handleKeyDown);
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isModalOpen, handleKeyDown, handleClickOutside]);

  // Function to handle starring a note
  const handleStarring = (noteId) => {
    if (onNoteStarring) {
      onNoteStarring(noteId);
    }
  };

  // Function to toggle star status inside modal
  const toggleStarStatus = () => {
    if (!selectedNote) return;

    const updatedNote = {
      ...selectedNote,
      noteStatus: selectedNote.noteStatus === "active" ? "starred" : "active",
    };

    setSelectedNote(updatedNote);
    handleStarring(selectedNote.id);
  };

  // Function to handle archiving of notes
  const handleArchiving = () => {
    if (!selectedNote) return;
    onNoteArchiving(selectedNote.id);
    handleCloseModal();
  };

  // Function to handle copying of the note content
  const handleCopy = () => {
    let SNNB = selectedNote.noteBody;
    let SNNE = selectedNote.noteEncryption;
    if (selectedNote && onNoteCopy) {
      onNoteCopy(SNNB, SNNE);
    }
  };

  // Function to handle deleting of notes
  const handleDeleting = () => {
    if (!selectedNote) return;
    onNoteDelete(selectedNote.id);
    handleCloseModal();
  };

  // Function to handle restoring of notes
  const handleRestoring = () => {
    if (!selectedNote) return;
    onNoteRestore(selectedNote.id);
    handleCloseModal();
  };

  // Function to handle permanent deletion of notes
  const handlePermanentDelete = () => {
    if (!selectedNote) return;
    onNotePermanentDelete(selectedNote.id);
    handleCloseModal();
  };

  // Function to handle label assignment
  const handleLabelChange = (label) => {
    setSelectedLabels((prevLabels) =>
      prevLabels.includes(label)
        ? prevLabels.filter((l) => l !== label)
        : [...prevLabels, label]
    );
  };

  // Function to save a new label
  const handleLabelSave = () => {
    if (selectedNote && selectedLabels.length > 0) {
      onLabelAssigning(selectedNote.id, selectedLabels);
    }
    setIsLabelModalOpen(false);
  };

  // Saves the changes made in editing mode
  const handleSaveEdit = () => {
    if (selectedNote) {
      onNoteSaveEdit(selectedNote.id, {
        noteTitle: selectedNote.noteTitle,
        noteBody: selectedNote.noteBody,
        lastEdited: serverTimestamp(),
      });

      // Set lastEdited to the current date locally
      setSelectedNote((prevNote) => ({
        ...prevNote,
        lastEdited: new Date(),
      }));

      setIsEditingMode(false);
    }
  };

  // Cancel the editing of note
  const handleCancelEdit = () => {
    setSelectedNote((prevNote) => ({
      ...prevNote,
      noteTitle: prevNote.originalNoteTitle,
      noteBody: prevNote.originalNoteBody,
    }));
    setIsEditingMode(false);
    handleCloseModal();
  };

  // Convert the note content to use Base 64 encoding
  const convertToBase64 = async (selectedNote) => {
    if (selectedNote) {
      const updatedNote = {
        ...selectedNote,
        noteEncryption: "base64",
        noteTitle: Base64Util.encode(selectedNote.noteTitle),
        noteBody: Base64Util.encode(selectedNote.noteBody),
      };
      setSelectedNote(updatedNote);

      // Save the updated note with Base 64 encoding to the database
      await onNoteSaveEdit(selectedNote.id, {
        noteEncryption: "base64",
        noteTitle: Base64Util.encode(selectedNote.noteTitle),
        noteBody: Base64Util.encode(selectedNote.noteBody),
        lastEdited: serverTimestamp(),
      });
    }

    setEncryptionErrorModal(false);
  };

  // Convert the note to use AES256 encryption
  const convertToAES256 = () => {
    return;
  };

  return (
    <div className="mt-8">
      {userNotes && userNotes.length > 0 ? (
        <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {userNotes.map((note) => {
            // Check encryption type and decode accordingly
            const decodedTitle =
              note.noteEncryption === "base64" && note.noteTitle
                ? Base64Util.decode(note.noteTitle)
                : note.noteTitle || "";

            const decodedBody =
              note.noteEncryption === "base64" && note.noteBody
                ? Base64Util.decode(note.noteBody)
                : note.noteBody || "";

            return (
              <div
                key={note.id}
                className="relative p-4 rounded border border-gray-200 hover:shadow-md transition-shadow duration-200 cursor-pointer"
                onClick={() => handleNoteClick(note)}
              >
                {note.noteStatus === "starred" && (
                  <div className="absolute top-0 right-0 mt-2 mr-2">
                    <AiFillStar className="text-yellow-400" />
                  </div>
                )}
                <h3 className="text-md font-medium mb-2 break-words">
                  {decodedTitle.length > 50
                    ? decodedTitle.slice(0, 50) + "..."
                    : decodedTitle}
                </h3>
                <p className="text-sm text-gray-700 break-words whitespace-pre-wrap">
                  {truncateText(decodedBody, 25)}
                </p>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="flex justify-center min-w-[calc(100vw-42rem)]">
          <div className="text-center text-gray-400">
            {userNotes ? "No notes available." : "Loading..."}
          </div>
        </div>
      )}

      {/* Note modal */}
      {isModalOpen && selectedNote && (
        <div className="modal-overlay fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50">
          <div
            className="relative flex flex-col bg-white rounded shadow-lg min-w-[25vw] max-w-[35vw] max-h-[80vh] pt-0 pb-6 pr-0"
            ref={noteModalRef}
          >
            <div className="flex-grow custom-scrollbar overflow-y-auto">
              {isEditingMode ? (
                <div>
                  <input
                    type="text"
                    value={
                      isEditingMode && selectedNote.noteEncryption === "base64"
                        ? Base64Util.decode(selectedNote.noteTitle)
                        : selectedNote.noteTitle || ""
                    }
                    onChange={(e) =>
                      setSelectedNote({
                        ...selectedNote,
                        noteTitle:
                          isEditingMode &&
                          selectedNote.noteEncryption === "base64"
                            ? Base64Util.encode(e.target.value)
                            : e.target.value,
                      })
                    }
                    className="text-xl font-semibold w-[98%] p-2 mx-2 mt-4 focus:outline-none"
                  />
                  <textarea
                    value={
                      isEditingMode && selectedNote.noteEncryption === "base64"
                        ? Base64Util.decode(selectedNote.noteBody)
                        : selectedNote.noteBody || ""
                    }
                    onChange={(e) =>
                      setSelectedNote({
                        ...selectedNote,
                        noteBody:
                          isEditingMode &&
                          selectedNote.noteEncryption === "base64"
                            ? Base64Util.encode(e.target.value)
                            : e.target.value,
                      })
                    }
                    className="text-left text-gray-700 w-[98%] h-[50vh] p-2 mx-2 mb-2 focus:outline-none resize-none custom-scrollbar overflow-y-auto"
                  />
                </div>
              ) : (
                <>
                  <h3 className="text-xl font-semibold break-words max-w-[98%] mt-4 mb-2 mr-4 pl-4">
                    {/* Render title based on current encryption */}
                    {selectedNote.noteEncryption === "base64" &&
                    selectedNote.noteTitle
                      ? Base64Util.decode(selectedNote.noteTitle)
                      : selectedNote.noteTitle || ""}
                  </h3>
                  <p className="text-left text-gray-700 whitespace-pre-wrap mr-4 mb-2 pl-4">
                    {/* Render body based on current encryption */}
                    {selectedNote.noteEncryption === "base64" &&
                    selectedNote.noteBody
                      ? Base64Util.decode(selectedNote.noteBody)
                      : selectedNote.noteBody || ""}
                  </p>

                  {/* Encryption type */}
                  {selectedNote.noteEncryption === currentSystemEncryption && (
                    <div
                      className="float-left text-sm font-medium rounded-md p-1 ml-4 mt-2 mb-3 cursor-pointer"
                      style={
                        selectedNote.noteEncryption === "base64" ||
                        selectedNote.noteEncryption === "aes256"
                          ? {
                              background:
                                "linear-gradient(135deg, #fff1b5, #c0a3fd, #7cadff)",
                              boxShadow: "0px 4px 6px rgba(128, 128, 128, 0.5)",
                            }
                          : { background: "#E5E7EB", borderRadius: "6px" }
                      }
                      onClick={
                        selectedNote.noteEncryption === "base64" ||
                        selectedNote.noteEncryption === "aes256"
                          ? () => setEncryptionBannerModal(true)
                          : null
                      }
                    >
                      <span className="font-normal text-gray-600">
                        encryption:
                      </span>{" "}
                      <span
                        className={
                          selectedNote.noteEncryption === "base64" ||
                          selectedNote.noteEncryption === "aes256"
                            ? "text-white"
                            : "text-gray-700"
                        }
                      >
                        {selectedNote.noteEncryption
                          ? selectedNote.noteEncryption === "base64"
                            ? "Base 64"
                            : selectedNote.noteEncryption === "aes256"
                            ? "AES 256"
                            : "None"
                          : "None"}
                      </span>
                    </div>
                  )}

                  {/* Error banner */}
                  {selectedNote.noteEncryption &&
                    selectedNote.noteEncryption !== currentSystemEncryption && (
                      <div
                        className="flex float-left bg-yellow-100 text-yellow-700 text-sm font-medium shadow-md rounded-md p-[0.30rem] ml-4 mt-2 mb-3 cursor-pointer"
                        onClick={() => setEncryptionErrorModal(true)}
                      >
                        <IoWarningOutline className="my-auto mr-1" />
                        <span>Note encryption error</span>
                      </div>
                    )}

                  {/* Created on / Last saved */}
                  <span className="float-right text-xs text-gray-500 mt-4 mr-4 my-2">
                    {selectedNote.lastEdited &&
                    typeof selectedNote.lastEdited.toDate === "function"
                      ? // If lastEdited exists and is a Firebase Timestamp
                        "Last Edited " +
                        format(
                          selectedNote.lastEdited.toDate(),
                          selectedNote.lastEdited.toDate().getFullYear() !==
                            new Date().getFullYear()
                            ? "MMMM d, yyyy"
                            : "MMMM d"
                        )
                      : selectedNote.lastEdited instanceof Date
                      ? // If lastEdited is a JavaScript Date object
                        "Last Edited " +
                        format(
                          selectedNote.lastEdited,
                          selectedNote.lastEdited.getFullYear() !==
                            new Date().getFullYear()
                            ? "MMMM d, yyyy"
                            : "MMMM d"
                        )
                      : selectedNote.save_timing &&
                        typeof selectedNote.save_timing.toDate === "function"
                      ? // If save_timing exists and is a Firebase Timestamp
                        "Created on " +
                        format(
                          selectedNote.save_timing.toDate(),
                          selectedNote.save_timing.toDate().getFullYear() !==
                            new Date().getFullYear()
                            ? "MMMM d, yyyy"
                            : "MMMM d"
                        )
                      : // Fallback if neither lastEdited nor save_timing are available
                        "Refresh to see date"}
                  </span>
                </>
              )}
            </div>

            {/* Accessibility bar */}
            <div className="flex h-full text-center bg-white py-2 -mb-6 border-t border-gray-200 rounded-b">
              <div className="flex space-x-8 ml-6 my-auto">
                {/* Star/favorite */}
                {showStar &&
                  !isEditingMode &&
                  (selectedNote.noteStatus === "starred" ? (
                    <AiFillStar
                      className="cursor-pointer text-yellow-400 hover:text-yellow-600 duration-200 ease-in-out"
                      title="Remove favorite"
                      size={20}
                      onClick={toggleStarStatus}
                    />
                  ) : (
                    <AiOutlineStar
                      className="cursor-pointer text-gray-600 hover:text-yellow-400 duration-200 ease-in-out"
                      title="Favorite"
                      size={20}
                      onClick={toggleStarStatus}
                    />
                  ))}

                {/* Labels */}
                {showLabels && !isEditingMode && (
                  <MdLabelOutline
                    className="cursor-pointer text-gray-600 hover:text-gray-500 duration-200 ease-in-out"
                    title="Add labels"
                    size={20}
                    onClick={() => setIsLabelModalOpen(!isLabelModalOpen)}
                  />
                )}

                {/* Archive */}
                {showArchive && !isEditingMode && (
                  <MdArchive
                    className="cursor-pointer text-gray-600 hover:text-gray-500 duration-200 ease-in-out"
                    title="Archive"
                    size={20}
                    onClick={handleArchiving}
                  />
                )}

                {/* Unarchive */}
                {showUnarchive && !isEditingMode && (
                  <MdUnarchive
                    className="cursor-pointer text-gray-600 hover:text-gray-500 duration-200 ease-in-out"
                    title="Unarchive"
                    size={20}
                    onClick={handleArchiving}
                  />
                )}

                {/* Copy noteBody */}
                {showCopy && !isEditingMode && (
                  <AiOutlineCopy
                    className="cursor-pointer text-gray-600 hover:text-gray-500 duration-200 ease-in-out"
                    title="Copy"
                    size={20}
                    onClick={handleCopy}
                  />
                )}

                {/* Edit note */}
                {showEdit && !isEditingMode && (
                  <AiOutlineEdit
                    className="cursor-pointer text-gray-600 hover:text-gray-500 duration-200 ease-in-out"
                    title="Edit"
                    size={20}
                    onClick={() => setIsEditingMode(true)}
                  />
                )}

                {/* Delete note */}
                {showDelete && !isEditingMode && (
                  <AiOutlineDelete
                    className="cursor-pointer text-gray-600 hover:text-red-400 duration-200 ease-in-out"
                    title="Delete"
                    size={20}
                    onClick={handleDeleting}
                  />
                )}

                {/* Undo and Delete permanently icons for '/bin' */}
                {selectedNote.noteStatus === "binned" && showPDAndRestore && (
                  <>
                    <AiOutlineUndo
                      className="cursor-pointer text-gray-600 hover:text-gray-500 duration-200 ease-in-out"
                      title="Restore this note"
                      size={20}
                      onClick={handleRestoring}
                    />
                    <AiOutlineDeletePermanently
                      className="cursor-pointer text-gray-600 hover:text-red-400 duration-200 ease-in-out"
                      title="Delete Permanently"
                      size={20}
                      onClick={handlePermanentDelete}
                    />
                  </>
                )}
              </div>

              {/* Close note */}
              {!isEditingMode && (
                <button
                  className="p-[6px] text-sm text-gray-600 rounded hover:bg-gray-200 ml-auto mr-2"
                  onClick={handleCloseModal}
                >
                  Close
                </button>
              )}

              {/* Save/Cancel note editing */}
              {isEditingMode && (
                <div className="flex ml-auto my-auto">
                  <button
                    className="p-2 text-sm text-gray-600 bg-gray-200 rounded hover:bg-gray-300 mr-2"
                    onClick={handleSaveEdit}
                  >
                    Save
                  </button>
                  <button
                    className="p-2 text-sm text-gray-600 bg-gray-200 rounded hover:bg-gray-300 mr-2"
                    onClick={handleCancelEdit}
                  >
                    Cancel
                  </button>
                </div>
              )}
            </div>
          </div>

          {/* Encryption banner info modal */}
          {encryptionBannerModal && (
            <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
              <div
                ref={EBModalRef}
                className="relative text-white bg-gradient-to-br from-[#fbe99a] via-[#c0a3fd] to-[#7cadff] rounded-lg shadow-md p-4 w-[25%]"
              >
                <div className="flex justify-between items-center mb-3 px-1">
                  <h2 className="text-lg font-semibold text-gray-700">
                    Note Encryption
                  </h2>
                  <FiX
                    className="text-lg cursor-pointer text-gray-700"
                    onClick={() => setEncryptionBannerModal(false)}
                    title="Close"
                  />
                </div>
                <div className="-mx-4 border bg-white" />
                <p className="text-gray-800 max-w-[35rem] my-3 text-center">
                  This note's content is encrypted/encoded with{" "}
                  <span className="font-semibold text-indigo-600">
                    {selectedNote.noteEncryption === "none"
                      ? "None"
                      : selectedNote.noteEncryption === "base64"
                      ? "Base 64"
                      : selectedNote.noteEncryption === "aes256"
                      ? "AES 256"
                      : ""}
                  </span>
                </p>
                <Link
                  to="/documentation/starchat"
                  className="flex items-center justify-center text-blue-600 text-sm hover:underline space-x-1"
                >
                  <span>Learn more</span>
                  <AiOutlineArrowRight className="text-xs text-blue-600" />
                </Link>
              </div>
            </div>
          )}

          {/* Encryption error modal */}
          {encryptionErrorModal && (
            <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
              <div
                ref={EEModalRef}
                className="relative text-gray-900 bg-white rounded-lg shadow-md p-4 w-[30%]"
              >
                <div className="flex justify-between items-center mb-3 px-1">
                  <h2 className="text-lg font-semibold">
                    Note Encryption Error
                  </h2>
                  <FiX
                    className="text-lg cursor-pointer"
                    onClick={() => setEncryptionErrorModal(false)}
                    title="Close"
                  />
                </div>
                <div className="-mx-4 border-t bg-gray-300" />
                {/* Disclaimer banner */}
                <div
                  className="flex items-center text-center py-[0.50rem] mt-3 text-sm font-medium text-yellow-700 bg-yellow-100 rounded-md shadow-md"
                  style={{
                    backgroundImage:
                      "repeating-linear-gradient(135deg, #fef9c3 0px, #fef9c3 8px, #fef08a 1px, #fef08a 10px)",
                  }}
                >
                  <span className="flex mx-auto">
                    <IoWarningOutline className="my-auto mr-1" />
                    This is a recommended action
                  </span>
                </div>
                {/* Info section */}
                <p className="text-gray-800 my-3 text-center">
                  It is advised that all notes follow the same encryption
                  method. Varying encryption methods across notes might lead to{" "}
                  <span className="text-red-500 font-medium">
                    errors while loading or editing notes.
                  </span>{" "}
                  To{" "}
                  <span className="text-green-500  font-medium">
                    resolve this error,
                  </span>{" "}
                  click the <span className="font-medium">'Convert note'</span>{" "}
                  button below.
                </p>
                <div className="flex justify-center mt-2">
                  <button
                    className="flex bg-blue-500 font-medium text-white p-2 rounded shadow-md focus:outline-none"
                    onClick={() =>
                      currentSystemEncryption === "base64"
                        ? convertToBase64(selectedNote)
                        : currentSystemEncryption === "aes256"
                        ? convertToAES256()
                        : null
                    }
                  >
                    <RiExchangeLine className="my-auto mr-1 text-2xl" />
                    Convert note encryption to{" "}
                    {currentSystemEncryption === "base64"
                      ? "Base 64"
                      : currentSystemEncryption === "aes256"
                      ? "AES 256"
                      : "None"}
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* Label modal */}
          {isLabelModalOpen && (
            <div className="modal-overlay fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white rounded shadow-lg w-[90vw] max-w-[20rem] max-h-[80vh] relative flex flex-col p-4">
                <span className="text-lg font-semibold mb-4">
                  Assign Labels
                </span>
                <div className="flex flex-col space-y-2">
                  {labels.map((label, index) => (
                    <li
                      key={index}
                      className={`flex items-center justify-between text-gray-800 bg-gray-100 p-2 mx-3 rounded cursor-pointer transition duration-200 ${
                        selectedLabels.includes(label) ? "bg-gray-200" : ""
                      }`}
                      onClick={() => handleLabelChange(label)}
                    >
                      <div className="flex items-center">
                        <span className="text-md font-medium">
                          {label.labelName}
                        </span>
                      </div>
                      <input
                        type="checkbox"
                        checked={selectedLabels.includes(label)}
                        readOnly
                        className="form-checkbox w-4 h-4"
                      />
                    </li>
                  ))}
                </div>
                <button
                  className="mt-4 p-[6px] text-sm text-gray-600 rounded hover:bg-gray-200 self-end"
                  onClick={handleLabelSave}
                >
                  Save
                </button>
                <button
                  className="mt-4 p-[6px] text-sm text-gray-600 rounded hover:bg-gray-200 self-end"
                  onClick={() => setIsLabelModalOpen(!isLabelModalOpen)}
                >
                  Close
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Note;
