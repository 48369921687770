import { BsPinAngleFill } from "react-icons/bs";

function ActiveChatList({
  chats,
  usernames,
  pinnedChats,
  currentUser,
  handleChatSelect,
}) {
  // Shorten email if it's too long
  const shortenEmail = (email, maxLength = 18) => {
    return email.length > maxLength ? email.slice(0, maxLength) + "..." : email;
  };

  // Merge pinned and unpinned chats
  const pinnedChatIds = pinnedChats.map((chat) => chat.id);
  const unpinnedChats = chats.filter(
    (chat) => !pinnedChatIds.includes(chat.id)
  );

  const sortedChats = [...pinnedChats, ...unpinnedChats];

  return (
    <>
      <div className="overflow-y-auto custom-scrollbar">
        {/* Display chat list */}
        {sortedChats.length === 0 ? (
          <div className="font-medium text-center text-gray-500 mt-[40vh]">
            Start a new chat!
          </div>
        ) : (
          sortedChats.map((chat, index) => {
            const otherParticipant =
              chat.participant1 === currentUser.email
                ? chat.participant2
                : chat.participant1;
            const otherParticipantName =
              usernames[otherParticipant] || "Unknown User";
            const otherParticipantEmail = shortenEmail(otherParticipant);

            return (
              <div
                key={index}
                className="relative border-b border-gray-300 hover:bg-gray-200 transition-colors duration-200"
              >
                <div
                  className="flex items-center justify-between w-[17.9rem] py-4 px-3 text-gray-900 cursor-pointer"
                  onClick={() =>
                    handleChatSelect(otherParticipantName, chat.id)
                  }
                  title={"Chat with " + otherParticipantName}
                >
                  <div className="flex">
                    <img
                      src="https://via.placeholder.com/40"
                      className="rounded-full"
                      alt="User avatar"
                    />
                    <div className="ml-3">
                      <span className="text-base font-medium">
                        {otherParticipantName}
                      </span>
                      <p className="text-xs text-gray-600">
                        {otherParticipantEmail}
                      </p>
                    </div>
                  </div>

                  {pinnedChatIds.includes(chat.id) ? (
                    <BsPinAngleFill className="absolute top-2 right-2 text-sm text-gray-500" />
                  ) : null}
                </div>
              </div>
            );
          })
        )}
      </div>
    </>
  );
}

export default ActiveChatList;
