export const Base64Util = {
  // Encode a given string into Base64
  encode: (input) => {
    try {
      // Convert the input string into a URI-encoded format, then encode it in Base64
      return btoa(encodeURIComponent(input));
    } catch (error) {
      alert("Something went wrong. Try again or contact website support.");
      return "";
    }
  },

  // Decode a Base64 encoded string back to its original format
  decode: (input) => {
    try {
      // Decode the Base64 string and then decode the URI-encoded format to get the original string
      return decodeURIComponent(atob(input));
    } catch (error) {
      alert("Something went wrong. Try again or contact website support.");
      return "";
    }
  },
};
