import React from "react";
import { Link } from "react-router-dom";

function StarChatSettings({ isNavOpen }) {
  return (
    <div
      className={`pl-2 pr-5 pb-4 -mr-6 -mt-5 space-y-6 overflow-y-auto custom-scrollbar ${
        isNavOpen ? "w-[calc(100vw-35rem)]" : "w-[calc(100vw-22rem)]"
      } h-[calc(100vh-2.5rem)]`}
    >
      <h2 className="my-4 text-xl font-medium">Star Chat</h2>

      {/* Notifications */}
      <div className="flex p-4 border border-gray-200 rounded-lg shadow-md">
        <div className="block">
          <h3 className="text-lg font-semibold text-gray-700">Notifications</h3>
          <p className="text-md text-gray-600">
            When someone sends you a message, do you want to receive a
            notification?
          </p>
        </div>
        <div className="flex items-center ml-auto space-x-4">
          <span className="text-sm text-gray-600">Off</span>
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              className="sr-only peer"
              // checked={currentSystemTwoFASetting}
              // onChange={(e) => handle2FAToggle(e.target.checked)}
            />
            <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-checked:bg-indigo-500 peer-focus:ring-2 transition-all" />
            <div className="absolute top-0.5 left-0.5 w-5 h-5 bg-white rounded-full shadow peer-checked:translate-x-5 transition-transform" />
          </label>
          <span className="text-sm text-gray-600">On</span>
        </div>
      </div>

      {/* Sound */}
      <div className="flex p-4 border border-gray-200 rounded-lg shadow-md">
        <div className="block">
          <h3 className="text-lg font-semibold text-gray-700">Sound</h3>
          <p className="text-md text-gray-600">
            If you are online and someone sends you a message, do you want to
            hear a 'new message' alert sound?
          </p>
        </div>
        <div className="flex items-center ml-auto space-x-4">
          <span className="text-sm text-gray-600">Off</span>
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              className="sr-only peer"
              // checked={currentSystemTwoFASetting}
              // onChange={(e) => handle2FAToggle(e.target.checked)}
            />
            <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-checked:bg-indigo-500 peer-focus:ring-2 transition-all" />
            <div className="absolute top-0.5 left-0.5 w-5 h-5 bg-white rounded-full shadow peer-checked:translate-x-5 transition-transform" />
          </label>
          <span className="text-sm text-gray-600">On</span>
        </div>{" "}
      </div>

      {/* Manage blocked users */}
      <div className="flex items-center p-4 border border-gray-200 rounded-lg shadow-md">
        <h3 className="text-lg font-semibold text-gray-700">Blocked users</h3>
        <Link
          to="/settings"
          className="text-sm ml-auto hover:underline hover:text-blue-500"
        >
          Manage blocked users
        </Link>
      </div>

      {/* Locked chats */}
      <div className="flex items-center p-4 border border-gray-200 rounded-lg shadow-md">
        <h3 className="text-lg font-semibold text-gray-700">Locked chats</h3>
        <Link
          to="/settings"
          className="text-sm ml-auto hover:underline hover:text-blue-500"
        >
          View locked users
        </Link>
        <button className="text-sm ml-6 p-2 rounded-md bg-indigo-100">
          Manage password
        </button>
      </div>
    </div>
  );
}

export default StarChatSettings;
