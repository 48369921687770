import {
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import Note from "../components/Note";
import { useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useLabels } from "../../context/LabelsContext";
import { Base64Util } from "../../utils/B64EncodeAndDecode";

function StarNoteLabelsView() {
  const { label } = useParams();
  const { labels } = useLabels();
  const [notes, setNotes] = useState([]);
  const { currentUser } = useContext(AuthContext);
  const collectionTitle = `notes-${currentUser.email}`;

  useEffect(() => {
    const getNotesByLabel = async () => {
      if (currentUser && label) {
        const labelQuery = query(
          collection(db, collectionTitle),
          where("label", "array-contains", { labelName: label })
        );
        const querySnapshot = await getDocs(labelQuery);
        setNotes(
          querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        );
      }
    };
    getNotesByLabel();
  }, [collectionTitle, currentUser, label]);

  // Function to handle the starring of a note
  const handleNoteStarring = async (noteId) => {
    const noteRef = doc(db, collectionTitle, noteId);
    const noteDoc = await getDoc(noteRef);
    if (!noteDoc.exists()) {
      console.log("No such document!");
      return;
    }
    const currentStatus = noteDoc.data().noteStatus;

    const newStatus = currentStatus === "active" ? "starred" : "active";
    await updateDoc(noteRef, {
      noteStatus: newStatus,
    });

    setNotes((prevNotes) =>
      prevNotes.map((note) =>
        note.id === noteId ? { ...note, noteStatus: newStatus } : note
      )
    );
  };

  // Function to handle the archiving of a note
  const handleNoteArchiving = async (noteId) => {
    const noteRef = doc(db, collectionTitle, noteId);
    await updateDoc(noteRef, {
      noteStatus: "archived",
    });

    setNotes((prevNotes) => prevNotes.filter((note) => note.id !== noteId));
  };

  // Function to handle copying of note content
  const handleNoteCopy = (SNNB, SNNE) => {
    navigator.clipboard
      .writeText(SNNE === "base64" ? Base64Util.decode(SNNB) : SNNB)
      .then(() => {
        alert("Note content copied to clipboard");
      })
      .catch(() => {
        alert("There was an error.");
      });
  };

  // Function to handle deletion of a note
  const handleNoteDelete = async (noteId) => {
    const noteRef = doc(db, collectionTitle, noteId);
    await updateDoc(noteRef, { noteStatus: "binned" });
    setNotes((prevNotes) => prevNotes.filter((note) => note.id !== noteId));
  };

  // Function to handle saving edits to a note
  const handleSaveEdit = async (noteId, updatedNote) => {
    const noteRef = doc(db, collectionTitle, noteId);
    await updateDoc(noteRef, updatedNote);

    setNotes((prevNotes) =>
      prevNotes.map((note) =>
        note.id === noteId ? { ...note, ...updatedNote } : note
      )
    );
  };

  // Function to assign labels to a note
  const handleLabelAssigning = async (noteId, label) => {
    const noteRef = doc(db, collectionTitle, noteId);
    await updateDoc(noteRef, { label });
    setNotes((prevNotes) =>
      prevNotes.map((note) => (note.id === noteId ? { ...note, label } : note))
    );
  };

  // Filter notes based on their noteStatus
  const filteredNotes = notes.filter((note) =>
    ["active", "starred", "archived"].includes(note.noteStatus)
  );

  return (
    <div className="relative flex-1 px-4 pt-3 bg-white">
      <div className="flex items-center">
        <h2 className="text-xl ml-2 mt-2 font-medium"># {label}</h2>
      </div>
      <Note
        labels={labels}
        userNotes={filteredNotes}
        showLabels={true}
        onNoteCopy={handleNoteCopy}
        onNoteDelete={handleNoteDelete}
        onNoteSaveEdit={handleSaveEdit}
        onNoteStarring={handleNoteStarring}
        onNoteArchiving={handleNoteArchiving}
        onLabelAssigning={handleLabelAssigning}
      />
    </div>
  );
}

export default StarNoteLabelsView;
