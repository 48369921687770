import { BiPhone } from "react-icons/bi";
import { MdArrowLeft } from "react-icons/md";
import { FiMessageSquare } from "react-icons/fi";
import { Base64Util } from "../../utils/B64EncodeAndDecode";

function ArchiveContactList({
  goHome,
  contacts,
  setSelectedContact,
  handleMessageClick,
}) {
  return (
    <div>
      {/* Contact Display */}
      <div className="w-72 border-t flex justify-center border-gray-300 py-2 mt-2">
        <MdArrowLeft
          size={24}
          className="rounded  p-1 hover:bg-gray-300 absolute left-2 cursor-pointer"
          onClick={() => goHome()}
          title="See all contacts"
        />

        <span className="font-semibold mx-auto">Archive</span>
      </div>
      <div className="w-[17.9rem] border-t border-gray-300" />
      <div
        className={`divide-y h-[calc(100vh-16rem)] divide-gray-200 custom-scrollbar ${
          contacts.length > 7 ? "overflow-y-scroll" : ""
        }`}
      >
        <ul>
          {contacts.map((contact) => (
            <li
              key={contact.id}
              className="p-4 hover:bg-gray-200 cursor-pointer flex items-center duration-150 ease-in-out"
              onClick={() => setSelectedContact(contact)}
            >
              <span>
                {contact.contactEncryption === "base64"
                  ? Base64Util.decode(contact.firstName) +
                    " " +
                    Base64Util.decode(contact.lastName)
                  : contact.firstName + " " + contact.lastName}
              </span>
              <BiPhone
                size={16}
                className="ml-auto"
                title={`Call ${
                  contact.contactEncryption === "base64"
                    ? Base64Util.decode(contact.firstName) +
                      " " +
                      Base64Util.decode(contact.lastName)
                    : contact.firstName + " " + contact.lastName
                }`}
              />
              <FiMessageSquare
                size={16}
                className="ml-3"
                title={`Message ${
                  contact.contactEncryption === "base64"
                    ? Base64Util.decode(contact.firstName) +
                      " " +
                      Base64Util.decode(contact.lastName)
                    : contact.firstName + " " + contact.lastName
                }`}
                onClick={() => handleMessageClick(contact)}
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default ArchiveContactList;
