import {
  doc,
  query,
  where,
  getDocs,
  deleteDoc,
  updateDoc,
  collection,
} from "firebase/firestore";
import "./card_content/aesCardIll.css";
import "./card_content/noneCardIll.css";
import "./card_content/baseCardIll.css";
import { auth, db } from "../../firebase";
import { FiTrash, FiX } from "react-icons/fi";
import { FaCheck, FaSpinner } from "react-icons/fa";
import { AuthContext } from "../../context/AuthContext";
import React, { useState, useRef, useEffect, useContext } from "react";
import { MdFontDownload, MdLockReset, MdSecurity } from "react-icons/md";
import { deleteUser, sendPasswordResetEmail, signOut } from "firebase/auth";
import { IoWarningOutline } from "react-icons/io5";

function AccountSettings({ isNavOpen }) {
  const modalRef = useRef(null);
  const fileInputRef = useRef(null);
  const [docId, setDocId] = useState();
  const [username, setUsername] = useState("");
  const { currentUser } = useContext(AuthContext);
  const [imageError, setImageError] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [successState, setSuccessState] = useState(false);
  const [pendingState, setPendingState] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isDAmodalOpen, setIsDAmodalOpen] = useState(false);
  const [isRPmodalOpen, setIsRPmodalOpen] = useState(false);
  const [currentSystemFont, setCurrentSystemFont] = useState("");
  const [selectedFontCard, setselectedFontCard] = useState(null);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [isUsernameModalOpen, setIsUsernameModalOpen] = useState(false);
  const [fontConfirmationModal, setFontConfirmationModal] = useState(false);
  const [currentSystemEncryption, setCurrentSystemEncryption] = useState("");
  const [selectedEncryptionCard, setselectedEncryptionCard] = useState(null);
  const [encryptionConfirmationModal, setEncryptionConfirmationModal] =
    useState(false);
  const [twoFactorAuthConfirmationModal, setTwoFactorAuthConfirmationModal] =
    useState(false);
  const [currentSystemTwoFASetting, setCurrentSystemTwoFASetting] =
    useState(false);

  useEffect(() => {
    // Query to retrieve document ID of current user
    const docIdQuery = query(
      collection(db, "users"),
      where("email", "==", currentUser.email)
    );
    // Fetch info from db
    const rInfo = async () => {
      (await getDocs(docIdQuery)).forEach((doc) => {
        setDocId(doc.id);
        setCurrentSystemFont(doc.data().systemFont);
        setCurrentSystemEncryption(doc.data().systemEncryption);
        setCurrentSystemTwoFASetting(doc.data().systemTwoFA);
      });
    };

    rInfo();
  }, [currentUser.email]);

  useEffect(() => {
    // Function to close modals when clicking outside or on Esc key press
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeAllModals();
      }
    };

    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        closeAllModals();
      }
    };

    const closeAllModals = () => {
      setIsUsernameModalOpen(false);
      setIsRPmodalOpen(false);
      setIsDAmodalOpen(false);
      setIsUploadModalOpen(false);
      setFontConfirmationModal(false);
      setEncryptionConfirmationModal(false);
      setTwoFactorAuthConfirmationModal(false);
    };

    // Add event listeners
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleKeyDown);

    // Cleanup event listeners on unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  // Function to handle changing of username
  const handleUsernameChange = async () => {
    const uc = doc(db, "users", docId);
    await updateDoc(uc, {
      username: username,
    });
    alert("Username has been updated!");
    setIsUsernameModalOpen(false);
  };

  // Function to handle password reset
  const handlePasswordReset = () => {
    sendPasswordResetEmail(auth, currentUser.email)
      .then(() => {
        alert(
          "Password reset email has been sent, check your email and then login with the new password."
        );
        signOut(auth);
        window.location.reload(false);
      })
      .catch((error) => {
        alert("There was an error: " + error.message);
      });
    setIsRPmodalOpen(false);
  };

  // Function to handle account deletion
  const handleAccountDeletion = async () => {
    setIsDAmodalOpen(false);

    // Update the modal to show the loading state
    setIsDAmodalOpen(true);
    setLoadingState(true);

    try {
      // Delete all documents in a colletion
      const deleteCollection = async (collectionName) => {
        const collectionRef = collection(db, collectionName);
        const snapshot = await getDocs(collectionRef);
        for (const doc of snapshot.docs) {
          await deleteDoc(doc.ref);
        }
      };

      // Delete chats where the user is a participant
      const deleteChats = async (field) => {
        const q = query(
          collection(db, "chats"),
          where(field, "==", currentUser.email)
        );
        const snapshot = await getDocs(q);
        for (const doc of snapshot.docs) {
          await deleteDoc(doc.ref);
        }
      };

      // Delete user document
      await deleteDoc(doc(db, "users", docId));

      // Delete user's associated collections if they exist
      const userCollections = [
        `contacts-${currentUser.email}`,
        `notes-${currentUser.email}`,
      ];
      for (const collectionName of userCollections) {
        await deleteCollection(collectionName);
      }

      // Delete chats where the user is a participant
      await deleteChats("participant1");
      await deleteChats("participant2");

      // Delete the user account
      await deleteUser(currentUser);

      setLoadingState(false);
      setSuccessState(true);

      // Delay for 5 seconds before reloading
      setTimeout(() => {
        window.location.reload(false);
      }, 5000);
    } catch (error) {
      alert(
        "An error occured (Try logging in again before attempting this action)" +
          error.message
      );
      // Reset loading state if there's an error
      setLoadingState(false);
    }
  };

  // Function to handle image uploads
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (!file.type.match("image/jpeg|image/jpg|image/png")) {
        setImageError("File must be an image (jpg, jpeg, png).");
        return;
      }
      if (file.size > 8 * 1024 * 1024) {
        setImageError("Your image exceeds the file size limit of 8MB.");
        return;
      }
      setImageError("");
      setSelectedImage(file);
      setIsUploadModalOpen(true);
      // Simulate image upload
      setIsUploading(true);
      let progress = 0;
      const interval = setInterval(() => {
        progress += 10;
        setUploadProgress(progress);
        if (progress >= 100) {
          clearInterval(interval);
          setIsUploading(false);
        }
      }, 200);
    }
  };

  // Function to CLOSE upload modal
  const handleUploadModalClose = () => {
    if (isUploading) {
      if (window.confirm("Do you want to cancel the image upload?")) {
        setIsUploadModalOpen(false);
        setSelectedImage(null);
        setUploadProgress(0);
      }
    } else {
      setIsUploadModalOpen(false);
    }
  };

  // Handle changes in font settings
  const handleFontSettingChange = async () => {
    const docRef = doc(db, "users", docId);
    await updateDoc(docRef, { systemFont: selectedFontCard });
    setFontConfirmationModal(false);
  };

  // Handle changes in encryption settings
  const handleEncryptionSettingChange = async () => {
    const docRef = doc(db, "users", docId);
    await updateDoc(docRef, { systemEncryption: selectedEncryptionCard });
    setEncryptionConfirmationModal(false);
  };

  // Handle changes in 2FA settings
  const handleTwoFactorAuthChanges = async (newState) => {
    try {
      const docRef = doc(db, "users", docId);
      await updateDoc(docRef, { systemTwoFA: newState });
      window.location.reload(true);
    } catch (error) {
      alert("An error occurred while updating two-factor authentication.");
    }
  };

  // Handle toggle action for Two-Factor Authentication (2FA)
  const handle2FAToggle = (newState) => {
    setPendingState(newState);
    setTwoFactorAuthConfirmationModal(true);
  };

  // Confirm the 2FA toggle action and update the database
  const confirm2FAToggle = async () => {
    await handleTwoFactorAuthChanges(pendingState);
    setTwoFactorAuthConfirmationModal(false);
  };

  return (
    <div
      className={`pl-2 pr-5 pb-4 -mr-6 -mt-5 space-y-6 overflow-y-auto custom-scrollbar ${
        isNavOpen ? "w-[calc(100vw-35rem)]" : "w-[calc(100vw-22rem)]"
      } h-[calc(100vh-2.5rem)]`}
    >
      <h2 className="my-4 text-xl font-medium">Account Settings</h2>
      {/* Username Change Card */}
      <div className="bg-white p-4 rounded-lg shadow-md border border-gray-200">
        <h3 className="text-lg font-semibold text-gray-700 mb-2">
          Change Username
        </h3>
        <div className="flex">
          <input
            type="text"
            placeholder="Enter new username"
            className="border border-gray-300 rounded p-2 w-full"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <button
            className={`px-3 py-2 my-auto ml-4 font-medium rounded duration-150 ease-in-out ${
              username
                ? "cursor-pointer bg-gray-100 shadow-md hover:shadow-indigo-500"
                : "cursor-not-allowed bg-gray-200 text-gray-400"
            }`}
            disabled={!username}
            onClick={() => setIsUsernameModalOpen(true)}
          >
            Submit
          </button>
        </div>
      </div>

      {/* Username Change Modal */}
      {isUsernameModalOpen && (
        <div className="fixed inset-0 -top-6 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
          <div
            ref={modalRef}
            className="relative bg-white rounded-lg shadow-md p-4 w-[90%] md:w-[30%] lg:w-[25%]"
          >
            <div className="flex justify-between items-center mb-3 px-1">
              <h2 className="text-lg font-semibold text-gray-800">
                Confirm Username Change
              </h2>
              <FiX
                className="text-lg text-gray-700 cursor-pointer"
                onClick={() => setIsUsernameModalOpen(false)}
                title="Close"
              />
            </div>
            <div className="-mx-4 mb-4 border border-t-gray-300" />
            <div className="text-center">
              <p className="text-gray-600 mb-2">
                Do you want your new username to be <strong>{username}</strong>?
              </p>
              <div className="flex justify-center space-x-4">
                <button
                  className="text-md font-medium text-blue-500 p-2 rounded hover:bg-blue-500 hover:text-white ease-in-out duration-200"
                  onClick={handleUsernameChange}
                >
                  Yes
                </button>
                <button
                  className="text-md font-medium text-gray-500 p-2 rounded hover:bg-gray-500 hover:text-white ease-in-out duration-200"
                  onClick={() => setIsUsernameModalOpen(false)}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Custom Themes */}
      <div className="space-y-3 p-3 border border-gray-200 rounded">
        <h3 className="text-lg font-medium text-gray-700">Custom Themes</h3>
        <div className="flex">
          <select className="w-full p-2 rounded-lg border outline-none border-gray-300 text-gray-800">
            <option value="default">Default</option>
            <option value="dark">Dark</option>
            <option value="light">Light</option>
            <option value="custom">High Contrast</option>
            <option value="custom">Rose</option>
            <option value="custom">Sunset</option>
          </select>
          <button className="w-28 text-white bg-blue-500 rounded ml-4">
            Set theme
          </button>
        </div>
      </div>

      <div className="flex space-x-16">
        {/* Image Card */}
        <div className="flex flex-col items-center p-4 rounded-md border border-gray-200">
          <input
            ref={fileInputRef}
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            className="hidden"
          />
          <h3 className="mb-4 text-lg font-medium self-start">
            Change profile picture:
          </h3>
          <div className="flex items-center space-x-2 mb-4">
            <img
              src={
                selectedImage
                  ? URL.createObjectURL(selectedImage)
                  : "https://via.placeholder.com/80"
              }
              alt="Profile"
              className="w-16 h-16 object-cover rounded-full"
            />
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded flex items-center"
              onClick={() => fileInputRef.current.click()}
            >
              Select
            </button>
          </div>
          {imageError && <p className="mt-2 text-red-500">{imageError}</p>}
          {isUploadModalOpen && (
            <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center">
              <div className="bg-white p-6 rounded-lg shadow-lg relative">
                <h3 className="mb-4 text-lg font-medium">Upload Image</h3>
                {selectedImage && (
                  <img
                    src={URL.createObjectURL(selectedImage)}
                    alt="Selected"
                    className="mb-4 w-32 h-32 object-cover rounded-full"
                  />
                )}
                <div className="w-full bg-gray-200 rounded-full h-4 mb-4">
                  <div
                    className="bg-blue-500 h-4 rounded-full"
                    style={{ width: `${uploadProgress}%` }}
                  ></div>
                </div>
                <button
                  className="bg-blue-500 text-white px-4 py-2 rounded disabled:opacity-50"
                  disabled={uploadProgress < 100}
                  onClick={() => setIsUploadModalOpen(false)}
                >
                  Set Profile Picture
                </button>
                <button
                  className="absolute top-2 right-2 text-gray-500"
                  onClick={handleUploadModalClose}
                >
                  <FiX />
                </button>
              </div>
            </div>
          )}
        </div>

        {/* Account Actions Card */}
        <div className="bg-white p-4 rounded-lg shadow-md border border-gray-200 w-[calc(100vw-38rem)] mx-auto">
          <h3 className="text-lg text-left font-semibold text-gray-700 mb-2">
            Account Actions
          </h3>
          <div className="flex mt-3 mb-1 mx-10 justify-between space-x-10">
            {/* Reset Password Section */}
            <div className="my-auto items-center p-3 rounded-lg border border-gray-200 max-w-[30rem]">
              <span>Reset Password:</span>
              <button
                className="text-blue-500 font-medium text-sm px-2 py-1 ml-2 rounded hover:text-white hover:bg-blue-500 duration-200 ease-in-out"
                onClick={() => setIsRPmodalOpen(true)}
              >
                Reset
              </button>
              <p className="mt-1 text-sm text-gray-500 leading-relaxed">
                Clicking <span className="font-semibold">Reset</span> sends a
                mail to your registered email address, containing a link through
                which you can change/reset your password.
              </p>
            </div>

            {/* Delete Account Section */}
            <div className="my-auto items-center p-3 rounded-lg border border-gray-200 max-w-[30rem]">
              <span>Delete Account:</span>
              <button
                className="text-red-500 text-sm font-medium px-2 py-1 ml-2 rounded hover:text-white hover:bg-red-500 duration-200 ease-in-out"
                onClick={() => setIsDAmodalOpen(true)}
              >
                Delete
              </button>
              <p className="mt-1 text-sm text-gray-500 leading-relaxed">
                Clicking <span className="font-semibold">Delete</span>{" "}
                permanently deletes your account and all associated information.
                Be cautious, as this is an irreversible action .
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Reset Password Modal */}
      {isRPmodalOpen && (
        <div className="fixed inset-0 -top-6 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
          <div
            ref={modalRef}
            className="relative bg-white rounded-lg shadow-md p-4 w-[90%] md:w-[30%] lg:w-[20%]"
          >
            <div className="flex justify-between items-center mb-3 px-1">
              <h2 className="text-lg font-semibold text-gray-800">
                Reset Password
              </h2>
              <FiX
                className="text-lg text-gray-700 cursor-pointer"
                onClick={() => setIsRPmodalOpen(false)}
                title="Close"
              />
            </div>
            <div className="-mx-4 mb-4 border border-gray-200" />
            <div className="text-center">
              <p className="text-gray-600 mb-2">
                Are you sure you want to reset your password?
              </p>
              <button
                className="flex text-md mx-auto font-medium text-blue-500 p-2 rounded hover:bg-blue-500 hover:text-white ease-in-out duration-200"
                onClick={handlePasswordReset}
              >
                <MdLockReset className="text-xl mt-[0.13rem] mr-1" />
                Reset Password
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Delete Account Modal */}
      {isDAmodalOpen && (
        <div className="fixed inset-0 -top-6 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
          <div
            ref={modalRef}
            className="relative bg-white rounded-lg shadow-md p-4 w-[90%] md:w-[30%] lg:w-[25%]"
          >
            <div className="flex justify-between items-center mb-3 px-1">
              <h2 className="text-lg font-semibold text-gray-800">
                Delete Account
              </h2>
              <FiX
                className="text-lg text-gray-700 cursor-pointer"
                onClick={() => setIsDAmodalOpen(false)}
                title="Close"
              />
            </div>
            <div className="-mx-4 mb-2 border border-gray-200" />
            {loadingState ? (
              <div className="flex items-center justify-center">
                <FaSpinner className="animate-spin text-blue-500 text-2xl mr-3" />
                <span>Deleting your account...</span>
              </div>
            ) : successState ? (
              <div className="flex items-center justify-center">
                <FaCheck className="text-green-500 text-2xl mr-3" />
                <span>Account successfully deleted</span>
              </div>
            ) : (
              <div className="text-center">
                {/* Disclaimer banner */}
                <div
                  className="flex items-center text-sm text-center py-[0.50rem] font-medium text-red-700 bg-red-100 rounded-md shadow-md"
                  style={{
                    backgroundImage:
                      "repeating-linear-gradient(135deg, #ffc3c3 0px, #ffc3c3 8px, #ffaeae 1px, #ffaeae 10px)",
                  }}
                >
                  <span className="flex font-medium mx-auto">
                    <IoWarningOutline className="mt-[0.20rem] mr-1" />
                    This is a irreversible action
                  </span>
                </div>
                <p className="text-gray-600 mt-2 mb-1">
                  Are you sure you want to delete your account? Your account and
                  all associated data will be deleted.
                </p>
                <button
                  className="flex text-md font-medium text-red-500 mx-auto p-2 rounded hover:bg-red-500 hover:text-white ease-in-out duration-200"
                  onClick={handleAccountDeletion}
                >
                  <FiTrash className="mt-[0.20rem] mr-1" />
                  Delete Account
                </button>
              </div>
            )}
          </div>
        </div>
      )}

      {/* Two-factor Authentication */}
      <div className="p-4 border border-gray-200 rounded-lg shadow-md">
        <h3 className="text-lg font-semibold text-gray-700 mb-2">
          Two-factor Authentication
        </h3>
        <p className="text-md text-gray-600 mb-4">
          Two-factor authentication (2FA) adds an extra layer of security to
          your account by requiring not only your password but also a
          verification code sent to your device. Enabling 2FA helps ensure that
          only you can access your account.
        </p>
        <div className="flex items-center justify-between mb-3">
          <p className="text-md font-medium text-gray-600 underline underline-offset-2">
            Current setting:
            <span
              className={`font-semibold inline-block ml-1 ${
                currentSystemTwoFASetting ? "text-green-600" : "text-red-600"
              }`}
            >
              {currentSystemTwoFASetting ? "ON" : "OFF"}
            </span>
          </p>
          <div className="flex items-center space-x-4">
            <span className="text-sm text-gray-600">Off</span>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer"
                checked={currentSystemTwoFASetting}
                onChange={(e) => handle2FAToggle(e.target.checked)}
              />
              <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-checked:bg-indigo-500 peer-focus:ring-2 transition-all" />
              <div className="absolute top-0.5 left-0.5 w-5 h-5 bg-white rounded-full shadow peer-checked:translate-x-5 transition-transform" />
            </label>
            <span className="text-sm text-gray-600">On</span>
          </div>
        </div>

        {/* Confirmation Modal */}
        {twoFactorAuthConfirmationModal && (
          <div className="fixed inset-0 -top-6 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
            <div
              ref={modalRef}
              className="relative bg-white rounded-lg shadow-md p-4 w-[90%] md:w-[30%] lg:w-[25%]"
            >
              <div className="flex justify-between items-center mb-3 px-1">
                <h2 className="text-lg font-semibold text-gray-800">
                  Update 2FA Settings
                </h2>
                <FiX
                  className="text-lg text-gray-700 cursor-pointer"
                  onClick={() => setTwoFactorAuthConfirmationModal(false)}
                  title="Close"
                />
              </div>
              <div className="-mx-4 mb-4 border border-t-gray-300" />
              <div className="text-center">
                <p className="text-gray-600 mb-2">
                  Are you sure you want to turn{" "}
                  {currentSystemTwoFASetting ? "off" : "on"} two-factor
                  authentication for your account?
                </p>
                <button
                  className="flex text-md mx-auto font-medium text-gray-600 p-2 rounded hover:bg-gradient-to-r from-[#9f7aea] to-[#3b82f6] hover:text-white ease-in-out duration-200"
                  onClick={confirm2FAToggle}
                >
                  <MdSecurity className="text-xl mt-[0.10rem] mr-1" />
                  Turn 2FA {currentSystemTwoFASetting ? "OFF" : "ON"}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Font Customization */}
      <div className="p-4 border border-gray-200 rounded-lg shadow-md">
        <h3 className="text-lg font-semibold text-gray-700 mb-2">
          Font Customization
        </h3>

        {/* Information Section */}
        <p className="mb-6 text-md text-gray-600 leading-6">
          Customizing the font changes the appearance of text across most of the
          interface, enhancing readability and aligning the look with your
          personal preferences. However, certain essential text elements, such
          as system-critical messages and navigation buttons, are designed to
          remain consistent to ensure usability and clarity.
        </p>

        <div className="space-y-14 mx-auto">
          <div className="flex justify-center space-x-14">
            {/* Poppins */}
            <div
              className={`w-1/3 pr-4 pl-2 pb-4 pt-1 border-2 rounded-lg cursor-pointer transition-shadow duration-300 ${
                selectedFontCard === "Poppins"
                  ? "shadow-lg shadow-indigo-500"
                  : ""
              }`}
              onClick={() => setselectedFontCard("Poppins")}
            >
              <span className="font-medium">Poppins</span>
              <h3 className="my-2 text-5xl text-center">Poppins</h3>
            </div>

            {/* Montserrat */}
            <div
              className={`w-1/3 pr-4 pl-2 pb-4 pt-1 border-2 rounded-lg cursor-pointer transition-shadow duration-300 ${
                selectedFontCard === "Montserrat"
                  ? "shadow-lg shadow-indigo-500"
                  : ""
              }`}
              onClick={() => setselectedFontCard("Montserrat")}
            >
              <span className="font-medium">Montserrat</span>
              <h3 className="my-2 text-5xl text-center font-mrat">
                Montserrat
              </h3>
            </div>
          </div>

          <div className="flex justify-center space-x-14">
            {/* Doto */}
            <div
              className={`w-1/3 pr-4 pl-2 pb-4 pt-1 border-2 rounded-lg cursor-pointer transition-shadow duration-300 ${
                selectedFontCard === "Doto" ? "shadow-lg shadow-indigo-500" : ""
              }`}
              onClick={() => setselectedFontCard("Doto")}
            >
              <span className="font-medium">Doto</span>
              <h3 className="mt-2 mb-1 text-6xl text-center font-doto">Doto</h3>
            </div>

            {/* Noto Serif */}
            <div
              className={`w-1/3 pr-4 pl-2 pb-4 pt-1 border-2 rounded-lg cursor-pointer transition-shadow duration-300 ${
                selectedFontCard === "Noto Serif"
                  ? "shadow-lg shadow-indigo-500"
                  : ""
              }`}
              onClick={() => setselectedFontCard("Noto Serif")}
            >
              <span className="font-medium">Noto Serif</span>
              <h3 className="my-2 text-5xl text-center font-nserif">
                Noto Serif
              </h3>
            </div>
          </div>
        </div>

        {/* Current Font */}
        <div className="flex justify-between items-center">
          <p className="text-md mt-8 font-medium underline underline-offset-2 text-gray-600">
            System Font:
            <span className="font-semibold inline-block ml-1">
              {currentSystemFont || "Poppins"}
            </span>
          </p>
          <button
            className={`px-3 py-2 mt-3 font-medium rounded duration-150 ease-in-out ${
              selectedFontCard
                ? "cursor-pointer shadow hover:shadow-indigo-500"
                : "cursor-not-allowed bg-gray-200 text-gray-400"
            }`}
            onClick={() => selectedFontCard && setFontConfirmationModal(true)}
            disabled={!selectedFontCard}
          >
            Save Font Setting
          </button>
        </div>
      </div>

      {/* Font confirmation modal */}
      {fontConfirmationModal && (
        <div className="fixed inset-0 -top-6 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
          <div
            ref={modalRef}
            className="relative bg-white rounded-lg shadow-md p-4 w-[90%] md:w-[30%] lg:w-[25%]"
          >
            <div className="flex justify-between items-center mb-3 px-1">
              <h2 className="text-lg font-semibold text-gray-800">
                Font Settings
              </h2>
              <FiX
                className="text-lg text-gray-700 cursor-pointer"
                onClick={() => setFontConfirmationModal(false)}
                title="Close"
              />
            </div>
            <div className="-mx-4 mb-4 border bg-gray-300" />
            <div className="flex flex-col items-center">
              <p className="text-gray-600 max-w-[30rem] mb-2 text-center">
                Are you sure you want to set your starrvault font to{" "}
                <span className="font-semibold">{selectedFontCard}</span>?
              </p>
              <button
                className="flex text-md font-medium text-gray-600 p-2 w-full md:w-auto rounded hover:bg-gradient-to-r from-[#9f7aea] to-[#3b82f6] hover:text-white ease-in-out duration-300"
                onClick={() => handleFontSettingChange()}
              >
                <MdFontDownload className="text-xl mt-[0.10rem] mr-1" />
                Set font to {selectedFontCard}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Encryption Widget */}
      <div className="p-4 border border-gray-200 rounded-lg shadow-md">
        <h3 className="text-lg font-semibold text-gray-700 mb-2">Encryption</h3>
        <p className="text-md text-gray-500 mb-4">
          Choose an encryption method to protect your data. Encrypting your data
          makes it more secure and less prone to attacks or hacks.{" "}
          <span className="font-semibold">Base 64</span> is a method of encoding
          which encodes your data and is the most basic step you can take
          towards data security. <span className="font-semibold">AES 256</span>{" "}
          offers industry-standard protection against unauthorized access. Click
          "Save Encryption Setting" to apply your choice.
        </p>

        <div className="flex space-x-4">
          {/* None */}
          <div
            className={`w-1/3 p-4 border-2 rounded-lg cursor-pointer text-center transition-shadow duration-300 ${
              selectedEncryptionCard === "none"
                ? "shadow-lg shadow-indigo-500"
                : ""
            }`}
            onClick={() => setselectedEncryptionCard("none")}
          >
            <div className="text-4xl mb-2">
              <div className="container">
                <div className="square" />
                <div className="square-two" />
                <div className="square-three" />
                <div className="square-four" />
                <div className="square-five" />
              </div>
            </div>
            <span className="font-medium">None</span>
          </div>

          {/* Base 64 */}
          <div
            className={`w-1/3 p-4 border-2 rounded-lg cursor-pointer text-center transition-shadow duration-300 ${
              selectedEncryptionCard === "base64"
                ? "shadow-lg shadow-indigo-500"
                : ""
            }`}
            onClick={() => setselectedEncryptionCard("base64")}
          >
            <div className="text-4xl mb-2">
              <div className="container">
                <div className="square">
                  <div className="l1" />
                  <div className="l2" />
                  <div className="cl1" />
                  <div className="cl2" />
                </div>
              </div>
            </div>
            <span className="font-medium">Base 64</span>
          </div>

          {/* AES 256 */}
          <div
            className={`w-1/3 p-4 border-2 rounded-lg relative cursor-not-allowed text-center transition-shadow duration-300 ${
              selectedEncryptionCard === "aes256"
                ? "shadow-lg shadow-indigo-500"
                : ""
            }`}
            // onClick={() => setselectedEncryptionCard("aes256")}
          >
            {/* Content to be blurred */}
            <div className="blur-sm pointer-events-none">
              <div className="container">
                <div className="pSquare" />
                <div className="concentric-square" />
                <div className="diagonal-line" />
                <div className="diagonal-line" />
                <div className="inner-circle" />
                <div className="star" />
              </div>
              <span className="font-medium">AES 256</span>
            </div>

            {/* "Coming Soon!" overlay */}
            <div className="absolute inset-0 flex items-center justify-center bg-gray-300 bg-opacity-50 rounded-lg">
              <span className="text-gray-700 text-lg font-medium">
                Coming soon
              </span>
            </div>
          </div>
        </div>

        {/* Current Encryption Status */}
        <div className="flex justify-between mt-4">
          <p className="text-md mt-8 font-medium underline underline-offset-2 text-gray-600">
            System Encryption:
            <span className="font-semibold inline-block ml-1">
              {currentSystemEncryption === "none" && "None"}
              {currentSystemEncryption === "base64" && "Base 64"}
              {currentSystemEncryption === "aes256" && "AES 256"}
              {!currentSystemEncryption && "None"}
            </span>
          </p>
          <button
            className={`px-3 py-2 mt-3 font-medium rounded duration-150 ease-in-out ${
              selectedEncryptionCard
                ? "cursor-pointer shadow hover:shadow-indigo-500"
                : "cursor-not-allowed bg-gray-200 text-gray-400"
            }`}
            onClick={() =>
              selectedEncryptionCard && setEncryptionConfirmationModal(true)
            }
            disabled={!selectedEncryptionCard}
          >
            Save Encryption Setting
          </button>
        </div>
      </div>

      {/* Encryption confirmation modal */}
      {encryptionConfirmationModal && (
        <div className="fixed inset-0 -top-6 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
          <div
            ref={modalRef}
            className="relative bg-white rounded-lg shadow-md p-4 w-[90%] md:w-[30%] lg:w-[25%]"
          >
            <div className="flex justify-between items-center mb-3 px-1">
              <h2 className="text-lg font-semibold text-gray-800">
                Encryption Settings
              </h2>
              <FiX
                className="text-lg text-gray-700 cursor-pointer"
                onClick={() => setEncryptionConfirmationModal(false)}
                title="Close"
              />
            </div>
            <div className="-mx-4 mb-4 border bg-gray-300" />
            <div className="flex flex-col items-center">
              <p className="text-gray-600 max-w-[30rem] mb-2 text-center">
                Are you sure you want to set your starrvault encryption to{" "}
                <span className="font-semibold">{selectedEncryptionCard}</span>?
              </p>
              <button
                className="flex text-md font-medium text-gray-600 p-2 w-full md:w-auto rounded hover:bg-gradient-to-r from-[#9f7aea] to-[#3b82f6] hover:text-white ease-in-out duration-300"
                onClick={() => handleEncryptionSettingChange()}
              >
                <MdSecurity className="mt-[0.24rem] mr-1" />
                Set Encryption to {selectedEncryptionCard}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AccountSettings;
