import {
  doc,
  where,
  query,
  getDocs,
  updateDoc,
  arrayUnion,
  collection,
  onSnapshot,
  arrayRemove,
} from "firebase/firestore";
import { db } from "../firebase";
import Sidebar from "./components/Sidebar";
import ErrorPage from "../appPages/ErrorPage";
import { AuthContext } from "../context/AuthContext";
import { useState, useEffect, useContext } from "react";
import { Base64Util } from "../utils/B64EncodeAndDecode";
import { useLocation, useNavigate } from "react-router-dom";
import ExpandedContactView from "./pages/ExpandedContactView";

function StarConnect({ isNavOpen }) {
  let navigate = useNavigate();
  const location = useLocation();
  const [labels, setLabels] = useState([]);
  const [contacts, setContacts] = useState([]);
  const { currentUser } = useContext(AuthContext);
  const [labelInput, setLabelInput] = useState("");
  const [editLabelId, setEditLabelId] = useState(null);
  const [showArchived, setShowArchived] = useState(false);
  const [editLabelValue, setEditLabelValue] = useState("");
  const [selectedContact, setSelectedContact] = useState(null);
  const [isAddingContact, setIsAddingContact] = useState(false);
  const [currentSystemEncryption, setCurrentSystemEncryption] = useState(null);

  // Fetch all the contacts saved by the user
  useEffect(() => {
    const getUserContactInfo = async () => {
      if (currentUser) {
        const q = query(collection(db, "contacts-" + currentUser.email));
        const querySnapshot = await getDocs(q);
        setContacts(
          querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        );
      }
    };
    getUserContactInfo(showArchived);
  }, [currentUser, showArchived]);

  // Get info on the user's system encryption
  useEffect(() => {
    // Retrieve user document in real-time
    const getUserDoc = () => {
      const docIdQuery = query(
        collection(db, "users"),
        where("email", "==", currentUser.email)
      );

      // Set up the Firestore listener
      const unsubscribeUserDoc = onSnapshot(docIdQuery, (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          setCurrentSystemEncryption(doc.data().systemEncryption);
        });
      });

      return unsubscribeUserDoc;
    };

    const unsubscribe = getUserDoc();

    // Cleanup function to unsubscribe from Firestore
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [currentUser.email]);

  // Fetch all the labels saved by the user
  useEffect(() => {
    const fetchLabels = async () => {
      if (currentUser) {
        try {
          const q = query(
            collection(db, "users"),
            where("email", "==", currentUser.email)
          );
          const snapshot = await getDocs(q);

          if (!snapshot.empty) {
            const userDoc = snapshot.docs[0];
            const userLabels = userDoc.data().contactLabels || [];
            setLabels(userLabels);
          }
        } catch (error) {
          console.error("Error fetching labels: ", error);
        }
      }
    };
    fetchLabels();
  }, [currentUser]);

  // Detect URL changes
  useEffect(() => {
    // Handle "add new contact" route
    if (location.pathname === "/starconnect/new") {
      setIsAddingContact(true);
      setSelectedContact(null);
    } else {
      setIsAddingContact(false);
    }

    // Determine if the current path is for archived contacts
    const isArchivedPath = location.pathname.includes("/starconnect/archive");
    setShowArchived(isArchivedPath);

    // Extract the contact name from the current URL path
    const contactName = isArchivedPath
      ? location.pathname.split("/starconnect/archive/")[1]
      : location.pathname.split("/starconnect/")[1];

    // Check if a contact name exists in the URL
    if (contactName) {
      // Find the contact in the list of contacts by matching the decoded full name
      const contact = contacts.find((c) => {
        let decodedFirstName = c.firstName;
        let decodedLastName = c.lastName;

        // Decode the first and last name if the contact encryption is base64
        if (c.contactEncryption === "base64") {
          decodedFirstName = Base64Util.decode(c.firstName);
          decodedLastName = c.lastName ? Base64Util.decode(c.lastName) : "";
        }

        // Combine the decoded first and last names into a full name
        const fullName = decodedLastName
          ? `${decodedFirstName}-${decodedLastName}`.toLowerCase()
          : decodedFirstName.toLowerCase();

        return fullName === contactName;
      });

      // If a matching contact is found, set it as the selected contact
      if (contact) {
        try {
          setSelectedContact(contact);
        } catch (err) {
          <ErrorPage />;
        }
      } else {
        <ErrorPage />;
      }
    }
  }, [location.pathname, contacts]);

  // Function to handle adding a new contact
  const handleAddContact = () => {
    setIsAddingContact(true);
    setSelectedContact(null);
    navigate("/starconnect/new");
  };

  // Go back to viewing all contacts
  const handleNavigateHome = () => {
    setSelectedContact(null);
    navigate("/starconnect");
  };

  // Function to handle selecting an existing contact
  const handleSelectContact = (selectedContact) => {
    let contactName;
    setIsAddingContact(false);
    setSelectedContact(selectedContact);

    // Check contact encryption
    if (selectedContact.contactEncryption === "base64") {
      const encodedFirstName = Base64Util.decode(
        selectedContact.firstName
      ).toLowerCase();

      const encodedLastName = selectedContact.lastName
        ? Base64Util.decode(selectedContact.lastName).toLowerCase()
        : "";

      // Construct the full contact name using decoded first and last names
      contactName = encodedLastName
        ? `${encodedFirstName}-${encodedLastName}`
        : encodedFirstName;
    } else {
      // Handle contacts without encryption
      contactName = selectedContact.lastName
        ? (
            selectedContact.firstName +
            "-" +
            selectedContact.lastName
          ).toLowerCase()
        : selectedContact.firstName.toLowerCase();
    }
    if (selectedContact.contactStatus === "archived") {
      navigate(`/starconnect/archive/${contactName}`);
    } else {
      navigate(`/starconnect/${contactName}`);
    }
  };

  // Function to handle archiving a contact
  const handleContactArchived = (contactId) => {
    setContacts((prevContacts) =>
      prevContacts.filter((contact) => contact.id !== contactId)
    );
    if (selectedContact && selectedContact.id === contactId) {
      setSelectedContact(null);
    }
  };

  // Check to make sure user exists before navigating to star chat
  const checkUserExistence = async (email) => {
    const q = query(collection(db, "users"), where("email", "==", email));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty;
  };

  // Function to handle adding a new label
  const handleAddLabel = async (labelInput) => {
    if (labelInput.trim() === "") return;

    const newLabel = { labelName: labelInput.trim() };
    const updatedLabels = [...labels, newLabel];
    setLabels(updatedLabels);

    if (currentUser) {
      try {
        const userRef = collection(db, "users");
        const q = query(userRef, where("email", "==", currentUser.email));
        const snapshot = await getDocs(q);

        if (!snapshot.empty) {
          const userDoc = snapshot.docs[0];
          const userDocRef = doc(db, "users", userDoc.id);
          await updateDoc(userDocRef, {
            contactLabels: arrayUnion(newLabel),
          });

          setLabelInput("");
        }
      } catch (error) {
        console.error("Error adding label: ", error);
      }
    }
  };

  // Function to handle the editing of a label
  const handleEditLabel = async (index, labelText) => {
    const oldLabel = labels[index];
    const updatedLabels = labels.map((label, i) =>
      i === index ? { labelName: labelText } : label
    );
    setLabels(updatedLabels);

    if (currentUser) {
      try {
        const userRef = collection(db, "users");
        const q = query(userRef, where("email", "==", currentUser.email));
        const snapshot = await getDocs(q);

        if (!snapshot.empty) {
          const userDoc = snapshot.docs[0];
          const userDocRef = doc(db, "users", userDoc.id);

          // Add the new label and then delete the old one
          await updateDoc(userDocRef, {
            contactLabels: arrayUnion({ labelName: labelText }),
          });

          await updateDoc(userDocRef, {
            contactLabels: arrayRemove(oldLabel),
          });

          // Reset editing state after successful update
          setEditLabelId(null);
          setEditLabelValue("");
        }
      } catch (error) {
        console.error("Error updating label: ", error);
      }
    }
  };

  // Handle the deletion of a label
  const handleDeleteLabel = async (index) => {
    const labeltoDelete = labels[index];
    const updatedLabels = labels.filter((_, i) => i !== index);
    setLabels(updatedLabels);

    if (currentUser) {
      try {
        const userRef = collection(db, "users");
        const q = query(userRef, where("email", "==", currentUser.email));
        const snapshot = await getDocs(q);

        if (!snapshot.empty) {
          const userDoc = snapshot.docs[0];
          const userDocRef = doc(db, "users", userDoc.id);
          await updateDoc(userDocRef, {
            contactLabels: arrayRemove(labeltoDelete),
          });
        }
      } catch (error) {
        console.error("Error deleting label");
      }
    }
  };

  return (
    <div
      className={`flex h-[calc(100%-1rem)] -mt-1 ${
        isNavOpen ? "ml-72" : "ml-14"
      }`}
    >
      <Sidebar
        labels={labels}
        contacts={contacts}
        labelInput={labelInput}
        editLabelId={editLabelId}
        showArchived={showArchived}
        setLabelInput={setLabelInput}
        onAddContact={handleAddContact}
        handleAddLabel={handleAddLabel}
        setEditLabelId={setEditLabelId}
        editLabelValue={editLabelValue}
        handleEditLabel={handleEditLabel}
        setShowArchived={setShowArchived}
        handleDeleteLabel={handleDeleteLabel}
        setEditLabelValue={setEditLabelValue}
        checkUserExistence={checkUserExistence}
        handleNavigateHome={handleNavigateHome}
        setSelectedContact={handleSelectContact}
        currentSystemEncryption={currentSystemEncryption}
      />
      <ExpandedContactView
        selectedContact={selectedContact}
        isAddingContact={isAddingContact}
        handleNavigateHome={handleNavigateHome}
        setIsAddingContact={setIsAddingContact}
        onContactArchived={handleContactArchived}
        currentSystemEncryption={currentSystemEncryption}
      />
    </div>
  );
}

export default StarConnect;
