import {
  BiPin,
  BiSend,
  BiPhone,
  BiVideo,
  BiLockAlt,
  BiPaperclip,
  BiDotsVerticalRounded,
} from "react-icons/bi";
import {
  doc,
  where,
  query,
  getDoc,
  updateDoc,
  arrayUnion,
  onSnapshot,
  collection,
} from "firebase/firestore";
import { format } from "date-fns";
import { v4 as uuidv4 } from "uuid";
import { db } from "../../firebase";
import EmojiPicker from "@emoji-mart/react";
import { FiTrash, FiX } from "react-icons/fi";
import { RiExchangeLine } from "react-icons/ri";
import { CgSmileMouthOpen } from "react-icons/cg";
import { IoWarningOutline } from "react-icons/io5";
import { MdArchive, MdBlock } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { Base64Util } from "../../utils/B64EncodeAndDecode";
import { useState, useEffect, useRef, useContext } from "react";
import { AiOutlineArrowRight, AiOutlineDelete } from "react-icons/ai";

function ExpandedChatView({
  name,
  pinChat,
  chatDocId,
  blockUser,
  deleteChat,
  archiveChat,
  archiveChatCopy,
}) {
  const modalRef = useRef(null);
  const location = useLocation();
  const EBModalRef = useRef(null);
  const EEModalRef = useRef(null);
  const textareaRef = useRef(null);
  const seeWhyModalRef = useRef(null);
  const emojiPickerRef = useRef(null);
  const archiveModalRef = useRef(null);
  const blockUserModalRef = useRef(null);
  const chatDeleteModalRef = useRef(null);
  const [messages, setMessages] = useState([]);
  const { currentUser } = useContext(AuthContext);
  const [isPinned, setIsPinned] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const [blockedAt, setBlockedAt] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const [displayName, setDisplayName] = useState("");
  const [P1BlockedP2, setP1BlockedP2] = useState(false);
  const [P2BlockedP1, setP2BlockedP1] = useState(false);
  const [showWhyModal, setShowWhyModal] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [participantStatus, setParticipantStatus] = useState("");
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [encryptionBanner, setEncryptionBanner] = useState(false);
  const [chatEncryptionType, setChatEncryptionType] = useState("");
  const [showBlockUserModal, setShowBlockUserModal] = useState(false);
  const [showChatDeleteModal, setShowChatDeleteModal] = useState(false);
  const [firstParticipantEmail, setFirstParticipantEmail] = useState("");
  const [encryptionErrorModal, setEncryptionErrorModal] = useState(false);
  const [secondParticipantEmail, setSecondParticipantEmail] = useState("");
  const [encryptionErrorBanner, setEncryptionErrorBanner] = useState(false);
  const [encryptionBannerModal, setEncryptionBannerModal] = useState(false);
  const [currentSystemEncryption, setCurrentSystemEncryption] = useState("");

  // Function to set the display name based on provided name prop
  useEffect(() => {
    const formatDisplayName = () => {
      if (name) {
        const formattedName = name
          .split("-")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
        setDisplayName(formattedName);
      } else {
        setDisplayName("");
      }
    };

    let unsubscribeUserDoc = null;

    // Retrieve user document in real-time
    const getUserDoc = () => {
      const docIdQuery = query(
        collection(db, "users"),
        where("email", "==", currentUser.email)
      );
      unsubscribeUserDoc = onSnapshot(docIdQuery, (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          setCurrentSystemEncryption(doc.data().systemEncryption);
        });
      });
    };

    // Fetch all chats/messages of the user
    const fetchMessages = async () => {
      if (chatDocId) {
        const chatDoc = await getDoc(doc(db, "chats", chatDocId));
        if (chatDoc.exists()) {
          setMessages(chatDoc.data().messages || []);
        } else {
          // If the chat doesn't exist (deleted), clear the messages
          setMessages([]);
        }
      }
    };

    formatDisplayName();
    getUserDoc();

    if (chatDocId) {
      fetchMessages();

      // Listen for real-time updates only if chatDocId is valid
      const unsubscribe = onSnapshot(
        doc(db, "chats", chatDocId),
        (docSnapshot) => {
          if (docSnapshot.exists()) {
            setMessages(docSnapshot.data().messages || []);
          } else {
            // If the chat was deleted, clear the messages
            setMessages([]);
          }
        }
      );

      return () => {
        if (unsubscribeUserDoc) unsubscribeUserDoc();
        unsubscribe();
      };
    }
  }, [name, chatDocId, currentUser.email]);

  // Handle emoji icon click
  const handleEmojiClick = (emoji) => {
    setNewMessage((prevMessage) => prevMessage + emoji.native);
    setShowEmojiPicker(false);
  };

  useEffect(() => {
    const handleEvent = (event) => {
      // Handle clicks outside modal and emoji picker
      if (
        emojiPickerRef.current &&
        !emojiPickerRef.current.contains(event.target)
      ) {
        setShowEmojiPicker(false);
      }
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowModal(false);
      }

      // Handle ESC key press
      if (event.type === "keydown" && event.key === "Escape") {
        setShowEmojiPicker(false);
        setShowModal(false);
      }
    };

    // Attach event listeners for both click and keydown
    document.addEventListener("mousedown", handleEvent);
    document.addEventListener("keydown", handleEvent);

    // Cleanup event listeners on component unmount
    return () => {
      document.removeEventListener("mousedown", handleEvent);
      document.removeEventListener("keydown", handleEvent);
    };
  }, []);

  useEffect(() => {
    const handleEvent = (event) => {
      // Handle clicks outside archive modal
      if (
        archiveModalRef.current &&
        !archiveModalRef.current.contains(event.target)
      ) {
        setShowArchiveModal(false);
      }

      // Handle clicks outside 'see why' modal
      if (
        seeWhyModalRef.current &&
        !seeWhyModalRef.current.contains(event.target)
      ) {
        setShowWhyModal(false);
      }

      // Handle clicks outside block user modal
      if (
        blockUserModalRef.current &&
        !blockUserModalRef.current.contains(event.target)
      ) {
        setShowBlockUserModal(false);
      }

      // Handle clicks outside chat deletion modal
      if (
        chatDeleteModalRef.current &&
        !chatDeleteModalRef.current.contains(event.target)
      ) {
        setShowChatDeleteModal(false);
      }

      // Handle clicks outside chat encryption modal
      if (EBModalRef.current && !EBModalRef.current.contains(event.target)) {
        setEncryptionBannerModal(false);
      }

      // Handle clicks outside chat encryption modal
      if (EEModalRef.current && !EEModalRef.current.contains(event.target)) {
        setEncryptionErrorModal(false);
      }

      // Handle ESC key press
      if (event.type === "keydown" && event.key === "Escape") {
        setShowArchiveModal(false);
        setShowWhyModal(false);
        setShowBlockUserModal(false);
        setShowChatDeleteModal(false);
      }
    };

    // Attach event listeners
    document.addEventListener("mousedown", handleEvent);
    document.addEventListener("keydown", handleEvent);

    // Cleanup event listeners
    return () => {
      document.removeEventListener("mousedown", handleEvent);
      document.removeEventListener("keydown", handleEvent);
    };
  }, [
    EBModalRef,
    EEModalRef,
    seeWhyModalRef,
    setShowWhyModal,
    archiveModalRef,
    blockUserModalRef,
    chatDeleteModalRef,
    setShowArchiveModal,
    setShowBlockUserModal,
    setShowChatDeleteModal,
  ]);

  useEffect(() => {
    const textarea = textareaRef.current;
    // Check if textarea is defined before accessing its properties
    if (textarea) {
      textarea.style.height = "auto";
      const scrollHeight = textarea.scrollHeight;
      const maxHeight = 4 * 24;
      textarea.style.height = `${Math.min(scrollHeight, maxHeight)}px`;
    }
  }, [newMessage]);

  // Function to facilitate messaging
  const handleSendMessage = async () => {
    if (newMessage.trim()) {
      const newMsg = {
        text:
          currentSystemEncryption === "base64"
            ? Base64Util.encode(newMessage)
            : newMessage,
        sender: currentUser.email,
        time: new Date().toISOString(),
        id: uuidv4(),
        messageEncryption:
          currentSystemEncryption === "base64" ||
          currentSystemEncryption === "aes256"
            ? currentSystemEncryption
            : "none",
      };
      setNewMessage("");

      // Save message to Firestore
      await updateDoc(doc(db, "chats", chatDocId), {
        messages: arrayUnion(newMsg),
      });
    }
  };

  // Facilitate next line functionality for messages
  const handleNextLine = (event) => {
    if (event.key === "Enter" && event.shiftKey) {
      return; // Allow new line
    } else if (event.key === "Enter") {
      event.preventDefault();
      handleSendMessage();
    }
  };

  useEffect(() => {
    // Fetch details of the user's chat
    const fetchChatDetails = async () => {
      if (!chatDocId) return;

      // Document reference and retrieval
      const chatDocRef = doc(db, "chats", chatDocId);
      const chatDocSnap = await getDoc(chatDocRef);
      if (!chatDocSnap.exists()) return;

      // Extract data from the document
      const chatData = chatDocSnap.data();
      const { participant1, participant2, pinnedByP1, pinnedByP2, messages } =
        chatData;

      // Handle PINNED status
      if (participant1 === currentUser.email) {
        setIsPinned(pinnedByP1);
      } else if (participant2 === currentUser.email) {
        setIsPinned(pinnedByP2);
      }

      // Handle ENCRYPTION type
      if (messages) {
        const currentUserMessages = messages.filter(
          (message) => message.sender === currentUser.email
        );

        // Get all encryption types of the current user's messages
        const encryptionTypes = currentUserMessages.map(
          (message) => message.messageEncryption
        );

        // Check if all encryption types are same
        const uniqueEncryptionTypes = [...new Set(encryptionTypes)];

        // Determine chat encryption type based on unique encryption types
        if (uniqueEncryptionTypes.length === 1) {
          setChatEncryptionType(uniqueEncryptionTypes[0] || "none");
        } else if (uniqueEncryptionTypes.length > 1) {
          setChatEncryptionType("error");
        } else {
          setChatEncryptionType("none");
        }
      }
    };

    fetchChatDetails();
  }, [chatDocId, currentUser.email]);

  useEffect(() => {
    // Manage encryption banner visibility
    if (
      (currentSystemEncryption === "base64" &&
        chatEncryptionType === "base64") ||
      (currentSystemEncryption === "aes256" &&
        chatEncryptionType === "aes256") ||
      (currentSystemEncryption === "none" &&
        (chatEncryptionType === "base64" ||
          chatEncryptionType === "aes256" ||
          chatEncryptionType === "none"))
    ) {
      setEncryptionBanner(true);
    } else {
      setEncryptionBanner(false);
    }

    // Manage encryption error banner visibility
    if (
      chatEncryptionType === "error" ||
      (currentSystemEncryption === "base64" &&
        (chatEncryptionType === "none" || chatEncryptionType === "aes256")) ||
      (currentSystemEncryption === "aes256" &&
        (chatEncryptionType === "none" || chatEncryptionType === "base64"))
    ) {
      setEncryptionErrorBanner(true);
    } else {
      setEncryptionErrorBanner(false);
    }
  }, [currentSystemEncryption, chatEncryptionType]);

  // useEffect to fetch participant status in real-time
  useEffect(() => {
    if (!chatDocId) return;

    const chatDocRef = doc(db, "chats", chatDocId);

    // Real-time listener for the chat document
    const unsubscribe = onSnapshot(chatDocRef, (chatDocSnap) => {
      if (chatDocSnap.exists()) {
        const chatData = chatDocSnap.data();
        const { participant1, participant2, P1Status, P2Status } = chatData;

        // Update participant status based on the current user's role
        if (participant1 === currentUser.email) {
          setParticipantStatus(P1Status);
        } else if (participant2 === currentUser.email) {
          setParticipantStatus(P2Status);
        }
      }
    });

    // Cleanup the listener on unmount or when chatDocId changes
    return () => unsubscribe();
  }, [chatDocId, currentUser.email]);

  // useEffect to fetch blocked status in real-time
  useEffect(() => {
    if (!chatDocId) return;

    const chatDocRef = doc(db, "chats", chatDocId);

    // Real-time listener for changes to the chat document
    const unsubscribe = onSnapshot(chatDocRef, (chatDocSnap) => {
      // Check if the document exists
      if (chatDocSnap.exists()) {
        const chatData = chatDocSnap.data();
        const {
          P1BlockedP2,
          P2BlockedP1,
          P1BlockedAt,
          P2BlockedAt,
          participant1,
          participant2,
        } = chatData;

        setFirstParticipantEmail(participant1);
        setSecondParticipantEmail(participant2);
        setP1BlockedP2(P1BlockedP2);
        setP2BlockedP1(P2BlockedP1);

        // Determine if the current user is blocked
        if (participant1 === currentUser.email) {
          setIsBlocked(P1BlockedP2);
          setBlockedAt(P1BlockedAt);
        } else if (participant2 === currentUser.email) {
          setIsBlocked(P2BlockedP1);
          setBlockedAt(P2BlockedAt);
        }
      }
    });

    // Cleanup function
    return () => unsubscribe();
  }, [chatDocId, currentUser.email]);

  // Function to convert messages to Base 64
  const convertToBase64 = async () => {
    const updatedMessages = messages.map((message) => {
      if (
        message.messageEncryption !== "base64" &&
        message.sender === currentUser.email
      ) {
        const updatedMessage = { ...message };
        updatedMessage.text = Base64Util.encode(message.text);
        updatedMessage.messageEncryption = "base64";
        return updatedMessage;
      }
      return message;
    });

    // Update messages in Firestore
    await updateDoc(doc(db, "chats", chatDocId), {
      messages: updatedMessages,
    });

    setEncryptionErrorModal(false);
  };

  // Function to convert messages to AES 256
  const convertToAES256 = async () => {
    return;
  };

  return (
    <div className="flex-1 flex flex-col justify-center border-y bg-gray-100 border-r border-gray-200 rounded-r-md relative">
      {chatDocId && participantStatus === "active" ? (
        <div className="flex items-center justify-between p-4 bg-gray-100 border-b border-gray-300">
          <div className="flex items-center space-x-4">
            <span className="text-lg font-semibold text-gray-900">
              {displayName}
            </span>
          </div>
          <div className="flex items-center space-x-4">
            {/* Encryption banner */}
            {encryptionBanner && (
              <div
                className="text-sm font-medium rounded-md p-1 mr-4 cursor-pointer"
                style={
                  chatEncryptionType === "base64" ||
                  chatEncryptionType === "aes256"
                    ? {
                        background:
                          "linear-gradient(135deg, #fff1b5, #c0a3fd, #7cadff)",
                        boxShadow: "0px 4px 6px rgba(128, 128, 128, 0.5)",
                      }
                    : { background: "#E5E7EB", borderRadius: "6px" }
                }
                onClick={() => setEncryptionBannerModal(true)}
              >
                <span className="font-normal text-gray-600">encryption:</span>{" "}
                <span
                  className={
                    chatEncryptionType === "base64" ||
                    chatEncryptionType === "aes256"
                      ? "text-white"
                      : "text-gray-700"
                  }
                >
                  {chatEncryptionType === "base64"
                    ? "Base 64"
                    : chatEncryptionType === "aes256"
                    ? "AES 256"
                    : "None"}
                </span>
              </div>
            )}

            {/* Error banner */}
            {encryptionErrorBanner && (
              <div
                className="flex items-center p-1 mr-4 text-sm font-medium text-yellow-700 bg-yellow-50 rounded-md shadow-md cursor-pointer"
                onClick={() => setEncryptionErrorModal(true)}
              >
                <IoWarningOutline />
                <span className="ml-1">Chat encryption error</span>
              </div>
            )}

            {/* Accessibility icons */}
            <BiPhone
              className="w-6 h-6 cursor-pointer text-gray-700 hover:text-gray-900"
              title="Coming soon!"
            />
            <BiVideo
              className="w-6 h-6 cursor-pointer text-gray-700 hover:text-gray-900"
              title="Coming soon!"
            />
            <BiDotsVerticalRounded
              className="w-6 h-6 cursor-pointer text-gray-700 hover:text-gray-900"
              onClick={() => setShowModal(!showModal)}
              title="See more options"
            />

            {/* More options modal */}
            {showModal && (
              <div
                ref={modalRef}
                className="absolute bg-white shadow-md rounded-md md:min-w-[8vw] sm:min-w-[12vw] right-2 top-14 p-2 z-50"
              >
                <ul className="space-y-2 text-[17px] mx-auto">
                  <li
                    className="flex text-gray-600 font-medium p-1 items-center rounded hover:bg-gray-100 cursor-pointer transition duration-200"
                    onClick={async () => {
                      await pinChat();
                      setIsPinned((prev) => !prev);
                    }}
                  >
                    <BiPin
                      className={`mx-1 transform transition-transform duration-300 ${
                        isPinned ? "" : "rotate-45"
                      }`}
                    />
                    {isPinned ? "Unpin Chat" : "Pin Chat"}
                  </li>
                  <li
                    className="flex text-gray-600 font-medium p-1 items-center rounded hover:bg-gray-100 cursor-pointer transition duration-200"
                    onClick={() => setShowArchiveModal(true)}
                  >
                    <MdArchive className="mx-1" /> Archive
                  </li>
                  <li className="flex text-gray-600 font-medium p-1 items-center rounded hover:bg-gray-100 cursor-pointer transition duration-200">
                    <BiLockAlt className="mx-1" /> Lock chat
                  </li>
                  <li
                    className="flex text-gray-400 font-medium p-1 items-center rounded hover:bg-gray-100 cursor-pointer transition duration-200"
                    onClick={() => setShowBlockUserModal(true)}
                  >
                    <MdBlock className="mx-1" />{" "}
                    {isBlocked ? "Unblock" : "Block"}
                  </li>
                  <li
                    className="flex text-red-500 font-medium p-1 items-center rounded hover:bg-gray-100 cursor-pointer transition duration-200"
                    onClick={() => setShowChatDeleteModal(true)}
                  >
                    <AiOutlineDelete className="mx-1" /> Delete chat
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      ) : null}

      {/* Encryption banner info modal */}
      {encryptionBannerModal &&
        (chatEncryptionType === "base64" ||
          chatEncryptionType === "aes256") && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
            <div
              ref={EBModalRef}
              className="relative text-white bg-gradient-to-br from-[#fbe99a] via-[#c0a3fd] to-[#7cadff] rounded-lg shadow-md p-4 w-[25%]"
            >
              <div className="flex justify-between items-center mb-3 px-1">
                <h2 className="text-lg font-semibold text-gray-700">
                  Chat Encryption
                </h2>
                <FiX
                  className="text-lg cursor-pointer text-gray-700"
                  onClick={() => setEncryptionBannerModal(false)}
                  title="Close"
                />
              </div>
              <div className="-mx-4 border bg-white" />
              <p className="text-gray-800 max-w-[35rem] my-3 text-center">
                All messages sent by you in this conversation are encoded with{" "}
                <span className="font-semibold text-indigo-600">
                  {chatEncryptionType === "none"
                    ? "None"
                    : chatEncryptionType === "base64"
                    ? "Base 64"
                    : chatEncryptionType === "aes256"
                    ? "AES 256"
                    : ""}
                </span>
              </p>
              <Link
                to="/documentation/starchat"
                className="flex items-center justify-center text-blue-600 text-sm hover:underline space-x-1"
              >
                <span>Learn more</span>
                <AiOutlineArrowRight className="text-xs text-blue-600" />
              </Link>
            </div>
          </div>
        )}

      {/* Encryption error modal */}
      {encryptionErrorModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
          <div
            ref={EEModalRef}
            className="relative text-gray-900 bg-white rounded-lg shadow-md p-4 w-[30%]"
          >
            <div className="flex justify-between items-center mb-3 px-1">
              <h2 className="text-lg font-semibold">Chat Encryption Error</h2>
              <FiX
                className="text-lg cursor-pointer"
                onClick={() => setEncryptionErrorModal(false)}
                title="Close"
              />
            </div>
            <div className="-mx-4 border-t bg-gray-300" />
            {/* Disclaimer banner */}
            <div
              className="flex items-center text-center py-[0.50rem] mt-3 text-sm font-medium text-yellow-700 bg-yellow-100 rounded-md shadow-md"
              style={{
                backgroundImage:
                  "repeating-linear-gradient(135deg, #fef9c3 0px, #fef9c3 8px, #fef08a 1px, #fef08a 10px)",
              }}
            >
              <span className="flex mx-auto">
                <IoWarningOutline className="my-auto mr-1" />
                This is a recommended action
              </span>
            </div>
            {/* Info section */}
            <p className="text-gray-800 my-3 text-center">
              All messages sent by a user in a chat must use the same type of
              encryption. Varying encryption methods could cause errors in{" "}
              <span className="text-red-500 font-medium">
                loading the chat messages, difficulty in sending messages,
              </span>{" "}
              and so on. To{" "}
              <span className="text-green-500  font-medium">
                resolve this error,
              </span>{" "}
              click the <span className="font-medium">'Convert all'</span>{" "}
              button below.
            </p>
            <div className="flex justify-center mt-2">
              <button
                className="flex bg-blue-500 font-medium text-white p-2 rounded shadow-md focus:outline-none"
                onClick={() =>
                  currentSystemEncryption === "base64"
                    ? convertToBase64(chatDocId)
                    : currentSystemEncryption === "aes256"
                    ? convertToAES256()
                    : null
                }
              >
                <RiExchangeLine className="my-auto mr-1 text-2xl" />
                Convert all messages to{" "}
                {currentSystemEncryption === "base64"
                  ? "Base 64"
                  : currentSystemEncryption === "aes256"
                  ? "AES 256"
                  : "None"}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Chat archiving modal */}
      {showArchiveModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
          <div
            ref={archiveModalRef}
            className="relative bg-white rounded-lg shadow-md p-4 w-[90%] md:w-[50%] lg:w-[40%] space-y-3"
          >
            <div className="flex justify-between items-center">
              <h2 className="text-lg font-semibold text-gray-800">
                Archive Chat
              </h2>
              <FiX
                className="text-lg text-gray-700 cursor-pointer"
                onClick={() => setShowArchiveModal(false)}
                title="Close"
              />
            </div>
            <div className="-mx-4 border bg-gray-300" />
            <div className="flex p-1">
              <div className="flex flex-col my-auto items-center">
                <p className="text-gray-600 max-w-[30rem] mb-4 text-center">
                  Archiving a copy will store a <i>read-only</i> version of the
                  conversation in your account for reference.
                </p>
                <button
                  className="bg-blue-600 text-white px-4 py-2 w-full md:w-auto rounded hover:bg-blue-700"
                  onClick={() => archiveChatCopy(setShowArchiveModal)}
                >
                  Archive a copy
                </button>
              </div>
              <div className="mx-8 -mb-5 -mt-4 border bg-gray-300" />
              <div className="flex flex-col my-auto items-center">
                <p className="text-gray-600 max-w-[35rem] mb-4 text-center">
                  Archiving will move the conversation to <i>archived chats</i>,
                  where you can continue chatting by visiting the archive.
                </p>
                <button
                  className="bg-blue-500 text-white px-4 py-2 w-full md:w-auto rounded hover:bg-blue-600"
                  onClick={() => archiveChat(setShowArchiveModal)}
                >
                  Archive
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Block user modal */}
      {showBlockUserModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
          <div
            ref={blockUserModalRef}
            className="relative bg-white rounded-lg shadow-md p-4 w-[90%] md:w-[30%] lg:w-[20%]"
          >
            <div className="flex justify-between items-center mb-3 px-1">
              <h2 className="text-lg font-semibold text-gray-800">
                {isBlocked ? "Unblock User" : "Block User"}
              </h2>
              <FiX
                className="text-lg text-gray-700 cursor-pointer"
                onClick={() => setShowBlockUserModal(false)}
                title="Close"
              />
            </div>
            <div className="-mx-4 mb-4 border bg-gray-300" />

            <div className="flex flex-col items-center">
              <p className="text-gray-600 max-w-[30rem] mb-2 text-center">
                Are you sure you want to {isBlocked ? "unblock" : "block"}{" "}
                <span className="font-semibold">{displayName}</span>?
              </p>
              <button
                className="flex text-md font-medium text-gray-400 p-2 w-full md:w-auto rounded hover:bg-gray-100 ease-in-out duration-200"
                onClick={() => blockUser(setShowBlockUserModal)}
              >
                <MdBlock className="mt-[0.24rem] mr-1" />
                {isBlocked ? "Unblock User" : "Block User"}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Chat deletion modal */}
      {showChatDeleteModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
          <div
            ref={chatDeleteModalRef}
            className="relative bg-white rounded-lg shadow-md p-4 w-[90%] md:w-[30%] lg:w-[20%]"
          >
            <div className="flex justify-between items-center mb-3 px-1">
              <h2 className="text-lg font-semibold text-gray-800">
                Delete Chat
              </h2>
              <FiX
                className="text-lg text-gray-700 cursor-pointer"
                onClick={() => setShowChatDeleteModal(false)}
                title="Close"
              />
            </div>
            <div className="-mx-4 mb-4 border bg-gray-300" />
            <div className="flex flex-col items-center">
              <p className="text-gray-600 max-w-[30rem] mb-2 text-center">
                The chats will be deleted for you. Are you sure you want to
                delete?
              </p>
              <button
                className="flex text-md font-medium text-red-500 p-2 w-full md:w-auto rounded hover:bg-red-500 hover:text-white ease-in-out duration-200"
                onClick={() => deleteChat(setShowChatDeleteModal)}
              >
                <FiTrash className="mt-[0.2rem] mr-1" />
                Delete chat
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Message display */}
      <div className="flex-1 my-0 p-4 space-y-4 bg-gray-100 custom-scrollbar overflow-y-auto">
        {chatDocId && participantStatus === "active" && messages.length > 0
          ? messages.map((msg, index) => {
              const isPreviousMessageFromSameSender =
                index > 0 && messages[index - 1].sender === msg.sender;
              return (
                <div
                  key={index}
                  className={`flex ${
                    msg.sender === currentUser.email
                      ? "justify-end"
                      : "justify-start"
                  }`}
                  style={{
                    marginTop: isPreviousMessageFromSameSender ? "0" : "1rem",
                  }}
                >
                  <div
                    className={`rounded-lg min-w-24 mb-3 ${
                      msg.sender === currentUser.email
                        ? "bg-blue-500 text-white"
                        : "bg-gray-300 text-gray-700"
                    }`}
                  >
                    <p className="text-md p-3 pb-0 max-w-[30rem] break-words overflow-hidden whitespace-pre-wrap">
                      {msg.messageEncryption === "base64"
                        ? Base64Util.decode(msg.text)
                        : msg.text}
                    </p>
                    <p
                      className={`text-[0.70rem] font-medium text-right mb-1 mr-[6px] ${
                        msg.sender === currentUser.email
                          ? "text-white"
                          : "text-gray-500"
                      }`}
                    >
                      {msg.time
                        ? (() => {
                            const messageDate = new Date(msg.time);
                            const now = new Date();
                            const differenceInDays =
                              (now - messageDate) / (1000 * 60 * 60 * 24);

                            if (differenceInDays < 1) {
                              return format(messageDate, "HH:mm");
                            } else if (differenceInDays < 30) {
                              return format(messageDate, "EEE. HH:mm");
                            } else if (
                              messageDate.getFullYear() === now.getFullYear()
                            ) {
                              return format(messageDate, "d MMM.");
                            } else {
                              return format(messageDate, "d MMM. yyyy");
                            }
                          })()
                        : "Loading..."}
                    </p>
                  </div>
                </div>
              );
            })
          : null}
      </div>

      {/* User blocked banner */}
      {isBlocked && !participantStatus === "active" ? (
        <div className="bg-gradient-to-r from-teal-200 via-teal-300 to-teal-200 text-teal-900 p-4 text-center">
          <p className="text-sm font-medium">
            You blocked {displayName} on{" "}
            {blockedAt
              ? blockedAt.toDate().toLocaleDateString("en-US", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                })
              : "N/A"}
          </p>
        </div>
      ) : null}

      {/* Resting info page */}
      <div
        className={`relative ${
          location.pathname === "/starchat" ? "min-h-[calc(100vh-4rem)]" : ""
        } bg-gray-100 flex flex-col items-center justify-center`}
      >
        {!chatDocId && (
          <div className="text-center mx-auto">
            <h1 className="text-4xl font-semibold text-gray-800 mb-8">
              Communication, secured
            </h1>
            <ul className="space-y-4 text-lg">
              <li>
                <Link to="/settings/starchat" className="text-blue-500">
                  Manage Settings
                </Link>
              </li>
              <li>
                <Link to="/starchat/archive" className="text-blue-500">
                  See Archive
                </Link>
              </li>
              <li>
                <Link to="/documentation/starchat" className="text-blue-500">
                  See Docs
                </Link>
              </li>
            </ul>
          </div>
        )}
      </div>

      {/* Message bar */}
      {chatDocId && participantStatus === "active" ? (
        <div className="flex items-center p-3 border-t border-gray-300 bg-gray-100">
          {(P1BlockedP2 && currentUser.email === secondParticipantEmail) ||
          (P2BlockedP1 && currentUser.email === firstParticipantEmail) ||
          (P1BlockedP2 && P2BlockedP1) ? (
            // Problem display bar
            <div className="flex justify-between items-center w-full text-gray-700 px-2">
              <p className="text-sm font-medium">
                You can no longer communicate with {displayName}
              </p>
              <span
                className="text-blue-600 text-sm font-medium cursor-pointer"
                onClick={() => setShowWhyModal(true)}
              >
                See why
              </span>
            </div>
          ) : (
            // Original message bar when not blocked
            <>
              <BiPaperclip
                className="w-6 h-6 cursor-pointer text-gray-400 mr-2"
                title="File uploads coming soon!"
              />
              <CgSmileMouthOpen
                className="w-6 h-6 cursor-pointer text-gray-400 mr-2"
                onClick={() => setShowEmojiPicker((prev) => !prev)}
                title="Select emoji"
              />
              {showEmojiPicker && (
                <div
                  ref={emojiPickerRef}
                  className="absolute bottom-20 left-10"
                >
                  <EmojiPicker onEmojiSelect={handleEmojiClick} />
                </div>
              )}
              <textarea
                ref={textareaRef}
                type="text"
                placeholder="Type your message..."
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                onKeyDown={(e) => handleNextLine(e)}
                className="flex-1 p-3 text-sm rounded-3xl bg-gray-200 focus:outline-none text-gray-900 resize-none custom-scrollbar overflow-y-auto max-h-[96px]"
                rows={1}
              />
              <button
                onClick={handleSendMessage}
                className="ml-2 p-1 rounded text-gray-600"
              >
                <BiSend className="w-6 h-6" title="Send message" />
              </button>
            </>
          )}
        </div>
      ) : null}

      {/* See why modal */}
      {showWhyModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
          <div
            ref={seeWhyModalRef}
            className="relative bg-white rounded-lg shadow-md p-4 w-[90%] md:w-[30%] lg:w-[20%]"
          >
            <div className="flex justify-between items-center mb-3 px-1">
              <h2 className="text-lg font-semibold text-gray-800">Note</h2>
              <FiX
                className="text-lg text-gray-700 cursor-pointer"
                onClick={() => setShowWhyModal(false)}
                title="Close"
              />
            </div>
            <div className="-mx-4 mb-4 border bg-gray-300" />
            <p className="text-gray-600 max-w-[30rem] mb-2 text-center">
              There might be several reasons why you're not able to chat. Click{" "}
              <i>learn more</i> to see further information on why you may not be
              able to chat with <b>{displayName}</b>.
            </p>
            <Link
              to="/documentation/starchat"
              className="flex items-center justify-center text-blue-500 text-sm hover:underline space-x-1"
            >
              <span>Learn more</span>
              <AiOutlineArrowRight className="text-xs" />
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}

export default ExpandedChatView;
